import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
//
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';

import Scrollbar from '../../../../components/scrollbar';
import Lightbox from '../../../../components/lightbox';
//
import ChatMessageItem from './ChatMessageItem';
import { useDispatch, useSelector } from '../../../../redux/store';
import {
  addMessage,
  getConversation,
  getCurrentContact,
  getMessages,
} from '../../../../redux/slices/chat';
import ChatMessageInput from './ChatMessageInput';
import { useAuthContext } from '../../../../auth/useAuthContext';
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
  conversation: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ChatMessageList({ conversation, open, onClose }) {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const { contact, totalMessages } = useSelector((state) => state.chat);
  const conversationId =
    conversation.receiver.id === 1 ? conversation.sender.id : conversation.receiver.id;
  const receiver = conversation.receiver.id === 1 ? conversation.sender : conversation.receiver;

  const [messages, setMessages] = useState([]);

  const handleSendMessage = (data) => {
    // const data=(

    // )
    dispatch(addMessage(conversationId, setMessages, data));
  };
  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [conversation.messages]);
  const [page, setPage] = useState(1);
  const pageCount = 10;
  useEffect(() => {
    dispatch(getMessages(conversationId, setMessages, page, pageCount));
    setPage((prev) => prev + 1);

    /* eslint-disable */
  }, [dispatch, conversationId]);
  useEffect(() => {
    const sock = new SockJS(`${process.env.REACT_APP_API_URL_TRIP}/ws`);
    const stompClient = Stomp.over(sock);
    stompClient.connect({}, (frame) => {
      const callback = (data) => {
        const dataArr = JSON.parse(data.body);
        console.log('arrive msg', dataArr);
        setMessages((msgs) => [...msgs, dataArr.object]);
      };
      const subscription = stompClient.subscribe(
        `/live/${conversationId}/backoffice-conversation`,
        callback
      );

      return () => {
        subscription.unsubscribe();
        stompClient.disconnect();
        console.log('Disconnected');
      };
    });

    return () => {
      sock.close();
    };
  }, [conversationId]);

  const hasNextPage = (page - 1) * pageCount < totalMessages;

  const fetchData = () => {
    dispatch(getMessages(conversationId, setMessages, page, pageCount));
    setPage((prev) => prev + 1);
  };
  // const imagesLightbox = conversation.messages
  //   .filter((messages) => messages.contentType === 'image')
  //   .map((messages) => ({ src: messages.body }));

  // const handleOpenLightbox = (imageUrl) => {
  //   const imageIndex = imagesLightbox.findIndex((image) => image.src === imageUrl);
  //   setSelectedImage(imageIndex);
  // };

  // const handleCloseLightbox = () => {
  //   setSelectedImage(-1);
  // };
  console.log('messages', messages);

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <div style={{ position: 'relative' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
          }}
        >
          <Iconify icon="eva:close-fill" />
        </IconButton>
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ py: 1 }}>
          <Typography variant="subtitle2">
            {receiver.firstname} {receiver.lastname}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {receiver.type}
          </Typography>
          {/* <Tooltip title="Reset">
          <Box sx={{ position: 'relative' }}>
            <IconButton 
            // onClick={onResetFilter}
            >
              <Iconify icon="ic:round-refresh" />
            </IconButton>
          </Box>
        </Tooltip> */}
        </Stack>
      </div>

      <Divider sx={{ borderStyle: 'dashed' }} />
      {/* <Scrollbar
        scrollableNodeProps={{
          ref: scrollRef,
        }}
        sx={{ p: 3, height: 1 }}
      > */}
      <div
        id="scrollableDiv"
        style={{
          padding: 3,
          height: '90vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          minWidth: 420,
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchData}
          hasMore={hasNextPage}
          scrollThreshold={1}
          inverse={true}
          scrollableTarget="scrollableDiv"
        >
          {messages.map((message) => (
            <ChatMessageItem
              key={message.id}
              message={message}
              conversation={conversation}
              // onOpenLightbox={() => handleOpenLightbox(message.body)}
              onOpenLightbox={() => {}}
            />
          ))}
        </InfiniteScroll>
      </div>
      {/* </Scrollbar> */}

      {/* <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      /> */}
      {/* <Stack direction="row" alignItems="center" pr={3}> */}
      <ChatMessageInput conversationId={conversationId} onSend={handleSendMessage} />

      {/* <iconify-icon icon="eva:navigation-2-fill"></iconify-icon> */}
      {/* </Stack> */}
    </Drawer>
  );
}
