import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  carsCheck: [],
  //   companyADSList: [],
  carCheck: null,
};

const slice = createSlice({
  name: 'carCheck',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET cars
    getCarsCheckSuccess(state, action) {
      state.isLoading = false;
      state.carsCheck = action.payload;
    },

    // GET car
    getCarCheckSuccess(state, action) {
      state.isLoading = false;
      state.carCheck = action.payload;
    },
    //
    // GET Company ADS
    // getCompanyADSSuccess(state, action) {
    //   state.isLoading = false;
    //   state.companyADSList = action.payload;
    // },
    // //  Create car
    // createCarSuccess(state, action) {
    //   state.isLoading = false;
    //   const newCar = action.payload;
    //   state.cars = [...state.cars, newCar];
    // },
    // Update car
    updateCarCheckStatusSuccess(state, action) {
      state.isLoading = false;
      state.carsCheck = state.carsCheck.map((car) => {
        if (car.id === action.payload.id) {
          return action.payload;
        }
        return car;
      });
    },
    // Delete car
    deleteCarCheckSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCarsSuccess,
  getCarSuccess,
  createCarSuccess,
  updateCarSuccess,
  deleteCarSuccess,
  getCompanyADSSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
/*eslint-disable*/
export function getCarsCheck() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_COMPANY}/api/v1/check`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      dispatch(slice.actions.getCarsCheckSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function getCarCheck(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`car/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      dispatch(slice.actions.getCarCheckSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

// export function createCar(car) {
//   console.log('car received:', car);
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car`,
//         car,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem('access_token')}`,
//           },
//         }
//       );
//       dispatch(slice.actions.createCarSuccess(response.data));
//       return response.status;
//     } catch (error) {
//       console.log(error);

//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------
export function updateCarCheck(id, car) {
  console.log('Data received', car);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('before response:', id, car);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car/${id}`,
        car,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      console.log('response:', response.data);
      dispatch(slice.actions.updateCarCheckSuccess(response.data));
      return response.status;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateCarCheckStatus(id, status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/check/${id}/${status}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      console.log('response:', response.data);
      dispatch(slice.actions.updateCarCheckStatusSuccess(response.data));
      return response.status;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

// export function getCompanyADS(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/find-by-company/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem('access_token')}`,
//           },
//         }
//       );
//       dispatch(slice.actions.getCompanyADSSuccess(response.data));
//       return response.status;
//     } catch (error) {
//       console.log(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
