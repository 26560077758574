/*eslint-disable*/
export function randomNumber(number) {
  return Math.floor(Math.random() * number) + 1;
}

export function randomNumberRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomInArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}
// Date format
export function getDateFormat() {
  const today = new Date();

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
// Reformat options
export function getObjectArrayOptions(options) {
  return options.map((item) => {
    if (item !== null) {
      return { id: item.id };
    }
  });
}
// New format for objects
export function getNewFormatObject(idObject) {
  return { id: idObject };
}

export function parseJSONWithoutQuotes(jsonString) {
  const stringWithoutQuotes = jsonString.replace(/"([^"]+)":/g, '$1:');
  return JSON.parse(stringWithoutQuotes);
}

// Object to String
export function getStringFromObj(obj) {
  let label;
  return (label = obj.label);
}
