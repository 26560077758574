import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// *DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

export const GeneralAnalyticsPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAnalyticsPage'))
);

// *DASHBOARD: Taxis
export const SocietiesListPage = Loadable(
  lazy(() => import('../pages/dashboard/SocietiesListPage'))
);
export const SocietieEditPage = Loadable(lazy(() => import('../pages/dashboard/SocietieEditPage')));
export const SocietieCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/SocietieCreatePage'))
);
// ----
export const LicencesListPage = Loadable(lazy(() => import('../pages/dashboard/LicencesListPage')));
export const LicencesEditPage = Loadable(lazy(() => import('../pages/dashboard/LicencesEditPage')));
export const LicencesCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/LicencesCreatePage'))
);
// ----
export const CarsListPage = Loadable(lazy(() => import('../pages/dashboard/CarsListPage')));
export const CarsEditPage = Loadable(lazy(() => import('../pages/dashboard/CarsEditPage')));
export const CarsCreatePage = Loadable(lazy(() => import('../pages/dashboard/CarsCreatePage')));
// ----
export const DriversListPage = Loadable(lazy(() => import('../pages/dashboard/DriversListPage')));
export const DriversEditPage = Loadable(lazy(() => import('../pages/dashboard/DriversEditPage')));
export const DriversCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/DriversCreatePage'))
);
// -----
export const LifeStatus = Loadable(lazy(() => import('../pages/dashboard/DriversStatusListPage')));
export const DriverPositioningHistoryPage = Loadable(
  lazy(() => import('../pages/dashboard/DriverPositioningHistoryPage'))
);
export const CarCheckPage = Loadable(lazy(() => import('../pages/dashboard/CarCheckPage')));

//* Dashboard Entites
export const ClientsListPage = Loadable(lazy(() => import('../pages/dashboard/ClientsListPage')));
export const ClientsCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/ClientsCreatePage'))
);
export const ClientsEditPage = Loadable(lazy(() => import('../pages/dashboard/ClientsEditPage')));
export const OrdersListPage = Loadable(lazy(() => import('../pages/dashboard/OrdersListPage')));
export const OrdersCreatePage = Loadable(lazy(() => import('../pages/dashboard/OrdersCreatePage')));
export const OrdersEditPage = Loadable(lazy(() => import('../pages/dashboard/OrdersEditPage')));
export const SecteursPage = Loadable(lazy(() => import('../pages/dashboard/ZonesPage')));
export const StationsPage = Loadable(lazy(() => import('../pages/dashboard/StationsPage')));
export const FavoriteAddressesListPage = Loadable(
  lazy(() => import('../pages/dashboard/FavoriteAddressesListPage'))
);
export const FavoriteAddressesCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/FavoriteAddressesCreatePage'))
);
export const FavoriteAddressesEditPage = Loadable(
  lazy(() => import('../pages/dashboard/FavoriteAddressesEditPage'))
);
export const AirportStationPage = Loadable(lazy(() => import('../pages/dashboard/AirportStation')));
export const AirportStationCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/AirportStationCreatePage'))
);
export const AirportStationEditPage = Loadable(
  lazy(() => import('../pages/dashboard/AirportStationEditPage'))
);
export const RayonsPage = Loadable(lazy(() => import('../pages/dashboard/RayonsPage')));
export const RayonsCreatePage = Loadable(lazy(() => import('../pages/dashboard/RayonsCreatePage')));
export const RayonEditPage = Loadable(lazy(() => import('../pages/dashboard/RayonEditPage')));
// nature
export const NatureListPage = Loadable(lazy(() => import('../pages/dashboard/NatureListPage')));
export const NatureCreatePage = Loadable(lazy(() => import('../pages/dashboard/NatureCreatePage')));
export const NatureEditPage = Loadable(lazy(() => import('../pages/dashboard/NatureEditPage')));

/* eslint-disable*/
// * Dashboard Resa & Courses
export const DispatchListPage = Loadable(lazy(() => import('../pages/dashboard/DispatchListPage')));
export const DispatchFinishedCourses = Loadable(
  lazy(() => import('../pages/dashboard/DispatchFinishedCourses'))
);
export const DispatchClientListPage = Loadable(
  lazy(() => import('../pages/dashboard/DispatchClientListPage'))
);

// * Dashboard administration
export const UsersListPage = Loadable(lazy(() => import('../pages/dashboard/UsersListPage')));
export const UsersCreatePage = Loadable(lazy(() => import('../pages/dashboard/UsersCreatePage')));
export const UsersEditPage = Loadable(lazy(() => import('../pages/dashboard/UsersEditPage')));
export const CommandsManagerPage = Loadable(
  lazy(() => import('../pages/dashboard/CommandsManagerPage'))
);
export const CarsOptionsListPage = Loadable(
  lazy(() => import('../pages/dashboard/CarsOptionsListPage'))
);
export const DriversOptionsListPage = Loadable(
  lazy(() => import('../pages/dashboard/DriversOptionsListPage'))
);
export const ServicessListPage = Loadable(
  lazy(() => import('../pages/dashboard/ServicesListPage'))
);
export const ServicessCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/ServicesCreatePage.js'))
);
export const DocumentsType = Loadable(lazy(() => import('../pages/dashboard/DocumentsType.js')));
export const ServicessEditPage = Loadable(
  lazy(() => import('../pages/dashboard/ServicesEditPage'))
);
// outils
export const NewsListPage = Loadable(lazy(() => import('../pages/dashboard/NewsListPage')));
export const NewsCreatePage = Loadable(lazy(() => import('../pages/dashboard/NewsCreatePage')));
export const NewsEditPage = Loadable(lazy(() => import('../pages/dashboard/NewsEditPage')));

// );

// export const GeneralEcommercePage = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralEcommercePage'))
// );
// export const GeneralAnalyticsPage = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralAnalyticsPage'))
// );
// export const GeneralBankingPage = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralBankingPage'))
// );
// export const GeneralBookingPage = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralBookingPage'))
// );
// export const GeneralFilePage = Loadable(lazy(() => import('../pages/dashboard/GeneralFilePage')));

// DASHBOARD: INVOICE
// export const InvoiceListPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceListPage')));
// export const InvoiceDetailsPage = Loadable(
//   lazy(() => import('../pages/dashboard/InvoiceDetailsPage'))
// );
// export const InvoiceCreatePage = Loadable(
//   lazy(() => import('../pages/dashboard/InvoiceCreatePage'))
// );
// export const InvoiceEditPage = Loadable(lazy(() => import('../pages/dashboard/InvoiceEditPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));
export const UserRolePage = Loadable(lazy(() => import('../pages/dashboard/RolePage')));

// DASHBOARD: BLOG
// export const BlogPostsPage = Loadable(lazy(() => import('../pages/dashboard/BlogPostsPage')));
// export const BlogPostPage = Loadable(lazy(() => import('../pages/dashboard/BlogPostPage')));
// export const BlogNewPostPage = Loadable(lazy(() => import('../pages/dashboard/BlogNewPostPage')));

// DASHBOARD: FILE MANAGER
// export const FileManagerPage = Loadable(lazy(() => import('../pages/dashboard/FileManagerPage')));

// DASHBOARD: APP
export const ChatPage = Loadable(lazy(() => import('../pages/dashboard/ChatPage')));
// export const MailPage = Loadable(lazy(() => import('../pages/dashboard/MailPage')));
// export const CalendarPage = Loadable(lazy(() => import('../pages/dashboard/CalendarPage')));
// export const KanbanPage = Loadable(lazy(() => import('../pages/dashboard/KanbanPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
// export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
// export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
// export const PaymentPage = Loadable(lazy(() => import('../pages/PaymentPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
