/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  driverDocuments: [],
  driverDocument: null,
};

const slice = createSlice({
  name: 'driverDocument',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getDriverDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.driverDocuments = action.payload;
    },
    getDriverDocumentSuccess(state, action) {
      state.isLoading = false;
      state.driverDocument = action.payload;
    },
    createDriverDocumentSuccess(state, action) {
      state.isLoading = false;
      const newdriverDocument = action.payload;
      state.driverDocuments = [...state.driverDocuments, newdriverDocument];
    },
    updateDriverDocumentSuccess(state, action) {
      state.isLoading = false;
      state.driverDocuments = state.driverDocuments.map((driverDoc) => {
        if (driverDoc.id === action.payload.id) {
          return action.payload;
        }
        return driverDoc;
      });
    },
    deleteDriverDocumentSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const {
  getDriverDocumentsSuccess,
  getDriverDocumentSuccess,
  createDriverTypeSuccess,
  updateDriverDocumentSuccess,
  deleteDriverDocumentSuccess,
} = slice.actions;

export function getDriverDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/driver-document`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      console.log(response.data);
      dispatch(slice.actions.getDriverDocumentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDriverDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/driver-document/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getDriverDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createDriverDocument(carDocument) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/driver-document`,
        carDocument,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createDriverDocumentSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDriverDocument(id, carDocument) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/driver-document/${id}`,
        carDocument,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateDriverDocumentSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDriverDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/driver-document/${id}`
      );
      dispatch(slice.actions.deleteDriverDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
