import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '../redux/store';
import { getUserAuthority } from '../redux/slices/users';
import { useAuthContext } from './useAuthContext';

const withUserAuthority = (method, controller, WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  // const { userAuthority } = useSelector((state) => state.useradmin);
  const { authoritiesuser } = useAuthContext();

  const hasAuthority =
    authoritiesuser?.adminAuthorities.some(
      (authority) =>
        authority.method.method === method && authority.controller.controller === controller
    ) || false;

  return hasAuthority ? (
    <WrappedComponent user={authoritiesuser} hasAuthority={hasAuthority} {...props} />
  ) : null;
};

export default withUserAuthority;
