import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  companies: [],
  company: null,
  totalCount: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET companies
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload;
    },

    // GET company
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },
    //  Create company
    createCompanySuccess(state, action) {
      state.isLoading = false;
      const newCompany = action.payload;
      state.companies = [...state.companies, newCompany];
    },
    // Update company
    updateCompanySuccess(state, action) {
      state.isLoading = false;
      state.companies = state.companies.map((company) => {
        if (company.id === action.payload.id) {
          return action.payload;
        }
        return company;
      });
    },
    // Delete Company
    deleteCompanySuccess(state, action) {
      state.isLoading = false;
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCompaniesSuccess,
  getCompanySuccess,
  createCompanySuccess,
  updateCompanySuccess,
  deleteCompanySuccess,
} = slice.actions;

// ----------------------------------------------------------------------
/*eslint-disable*/
export function getCompanies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company/get-all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getCompaniesSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCompany(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company/get-by-id/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getCompanySuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createCompany(company) {
  console.log('company: ', company);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken =
        'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIrMzM2MTIzNDU2NzgiLCJpYXQiOjE3MDM3NTI4OTYsImV4cCI6MTcwNjM0NDg5Nn0.OyBrZmutyOm9WaiCK4HcgsWiPPcMyGWWmezRsApAWOA';
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company/add`,
        company,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createCompanySuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateCompany(id, company) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company/${id}`,
        company,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateCompanySuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
