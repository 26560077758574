import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
// import { DatePicker } from '@mui/x-date-pickers';
import { enGB } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { namespace } from 'stylis';
import { format } from 'date-fns';
// import dayjs from 'dayjs';
import frLocale from 'date-fns/locale/fr';

KeyboardDatePickerExample.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

function KeyboardDatePickerExample({ label, name }) {
  const [selected, setSelected] = useState();
  const { control, watch } = useFormContext();

  const values = watch();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DateTimePicker
            label={label}
            value={field.value}
            minDateTime={new Date()}
            // minutesStep={5}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy HH:mm"
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default KeyboardDatePickerExample;
