import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  controllerMethods: [],
};

const controllerMethodsSlice = createSlice({
  name: 'controllerMethods',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getControllerMethodsSuccess(state, action) {
      state.isLoading = false;
      state.controllerMethods = action.payload;
    },
  },
});

export default controllerMethodsSlice.reducer;

export const { startLoading, hasError, getControllerMethodsSuccess } =
  controllerMethodsSlice.actions;

export function getControllerMethods() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/controller-method`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(getControllerMethodsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
