import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { fr } from 'date-fns/locale';

// @mui
import { Avatar, Typography, Stack } from '@mui/material';
// components
import Image from '../../../../components/image';

// ----------------------------------------------------------------------

// const CURRENT_USER_ID = '8864c717-587d-472a-929a-8e5f298024da-0';
const CURRENT_USER_ID = 1;

ChatMessageItem.propTypes = {
  message: PropTypes.object,
  conversation: PropTypes.object,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({ message, conversation, onOpenLightbox }) {
  const { sender, receiver } = conversation;
  const notBackOffice = sender.id === 1 ? receiver : sender;
  // const sender = conversation.participants.find(
  //   (participant) => participant.id === message.senderId
  // );
  // const sender=conversation.sender

  const senderDetails =
    message.sender.id !== notBackOffice.id
      ? {
          type: 'me',
        }
      : {
          avatar: notBackOffice?.picture,
          name: notBackOffice?.firstname,
        };

  const currentUser = senderDetails.type === 'me';

  // const isImage = message.contentType === 'image';
  const isImage = false;
  const firstName = senderDetails.name && senderDetails.name.split(' ')[0];
  // const firstName=
  return (
    <Stack
      direction="row"
      justifyContent={currentUser ? 'flex-end' : 'unset'}
      sx={{ mb: 3, px: 1 }}
    >
      {!currentUser && (
        <Avatar
          alt={senderDetails.firstname}
          src={`https://file-uploaded.s3.eu-west-3.amazonaws.com/${senderDetails.avatar}`}
          // src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          sx={{ width: 32, height: 32, mr: 2 }}
        />
      )}

      <Stack spacing={1} alignItems="flex-end">
        <Typography
          noWrap
          variant="caption"
          sx={{
            color: 'text.disabled',
            ...(!currentUser && {
              mr: 'auto',
            }),
          }}
        >
          {!currentUser && `${firstName},`} &nbsp;
          {formatDistanceToNowStrict(new Date(message.sentAt), {
            addSuffix: true,
            locale: fr,
          })}
        </Typography>

        <Stack
          sx={{
            p: 1.5,
            minWidth: 48,
            maxWidth: 320,
            borderRadius: 1,
            // overflow: 'hidden',
            overflowWrap: 'break-word',
            typography: 'body2',
            bgcolor: 'background.neutral',
            ...(currentUser && {
              color: 'grey.100',
              bgcolor: '#10b981',
            }),
            ...(isImage && {
              p: 0,
            }),
          }}
        >
          {isImage ? (
            <Image
              alt="attachment"
              src={message.body}
              onClick={() => onOpenLightbox(message.body)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.9,
                },
              }}
            />
          ) : (
            message.content
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
