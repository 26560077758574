/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosEntites';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  airportStations: [],
  FiltredAirportStations: [],
  airportStation: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'airport-station',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET favoriteAddresss
    getAirportStationsSuccess(state, action) {
      state.isLoading = false;
      state.airportStations = action.payload;
    },

    // GET favoriteAddress
    getAirportStationSuccess(state, action) {
      state.isLoading = false;
      state.airportStation = action.payload;
    },
    //  Create favoriteAddress
    createAirportStationSuccess(state, action) {
      state.isLoading = false;
      const newAirportStation = action.payload;
      state.airportStations = [...state.airportStations, newAirportStation];
    },
    // Update favoriteAddress
    updateAirportStationSuccess(state, action) {
      state.isLoading = false;
      state.airportStations = state.airportStations.map((airportStation) => {
        if (airportStation.id === action.payload.id) {
          return action.payload;
        }
        return airportStation;
      });
    },
    // Delete favoriteAddress
    deleteAirportStationSuccess(state, action) {
      state.isLoading = false;
      const airportStationId = action.payload;
      state.airportStations = state.airportStations.filter(
        (event) => event.id !== airportStationId
      );
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.FiltredAirportStations = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getAirportStationsSuccess,
  getAirportStationSuccess,
  createAirportStationSuccess,
  updateAirportStationSuccess,
  deleteAirportStationSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getAirportStations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/airport-station`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getAirportStationsSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getAirportStationsFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/airport-station/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAirportStation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/airport-station/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getAirportStationSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createAirportStation(airportStation) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/airport-station`,
        airportStation,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createAirportStationSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateAirportStation(id, airportStation) {
  console.log('id:', id, 'favoriteAddress:', airportStation);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/airport-station/${id}`,
        airportStation,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateAirportStationSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteAirportStation(airportStationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/airport-station/${airportStationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteAirportStationSuccess({ airportStationId }));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
