import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  finishedSelectedTrip: [],
};

const finishedSelectedTripSlice = createSlice({
  name: 'finishedSelectedTrip',
  initialState,
  reducers: {
    setFinishedSelectedTrip(state, action) {
      state.finishedSelectedTrip = action.payload;
    },
    clearFinishedSelectedTrip(state) {
      state.finishedSelectedTrip = [];
    },
    addFinishedSelectedTrip(state, action) {
      const trip = action.payload;
      state.finishedSelectedTrip = [...state.finishedSelectedTrip, trip];
    },
    addAllFinishedSelectedTrip(state, action) {
      const trip = action.payload;
      state.finishedSelectedTrip = [...state.finishedSelectedTrip, trip];
    },
    removeFinishedSelectedTrip(state, action) {
      const trip = action.payload;
      state.finishedSelectedTrip = state.finishedSelectedTrip.filter(
        (selectedTrip) => selectedTrip.id !== trip.id
      );
    },
  },
});
export const {
  setFinishedSelectedTrip,
  clearFinishedSelectedTrip,
  addFinishedSelectedTrip,
  addAllFinishedSelectedTrip,
  removeFinishedSelectedTrip,
} = finishedSelectedTripSlice.actions;
export default finishedSelectedTripSlice.reducer;
