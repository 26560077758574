import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { List } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// components
import { SkeletonConversationItem } from '../../../../components/skeleton';
//
import ChatNavItem from './ChatNavItem';

// ----------------------------------------------------------------------

const CURRENT_USER_ID = '8864c717-587d-472a-929a-8e5f298024da-0';

ChatNavList.propTypes = {
  sx: PropTypes.object,
  openNav: PropTypes.bool,
  selected: PropTypes.func,
  onCloseNav: PropTypes.func,
  conversations: PropTypes.object,
  setSelectedContact: PropTypes.func,
  setOpenDrawer: PropTypes.func,
};

export default function ChatNavList({
  conversations,
  openNav,
  onCloseNav,
  selected,
  setSelectedContact,
  setOpenDrawer,
  sx,
  ...other
}) {
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const handleSelectConversation = (conversation) => {
    // let conversationKey = '';

    // const conversation = conversations.byId[conversationId];

    // if (conversation.type === 'GROUP') {
    //   conversationKey = conversation.id;
    // } else {
    //   const otherParticipant = conversation.participants.find(
    //     (participant) => participant.id !== CURRENT_USER_ID
    //   );

    //   if (otherParticipant?.username) {
    //     conversationKey = otherParticipant?.username;
    //   }
    // }
    setSelectedContact(conversation);
    setOpenDrawer(true);
    // navigate(PATH_DASHBOARD.chat.view(conversation.id));
  };

  // const loading = !conversations.allIds.length;
  const loading = !conversations.length;

  return (
    <List disablePadding sx={sx} {...other}>
      {(loading ? [...Array(12)] : conversations).map((conversation, index) =>
        conversation ? (
          <ChatNavItem
            // key={conversationId}
            key={index}
            openNav={openNav}
            conversation={conversation}
            // conversation={conversations.byId[conversationId]}
            // isSelected={selected(conversationId)}
            isSelected={selected(conversation?.id)}
            onSelect={() => {
              if (!isDesktop) {
                onCloseNav();
              }
              handleSelectConversation(conversation);
            }}
          />
        ) : (
          <SkeletonConversationItem key={index} />
        )
      )}
    </List>
  );
}
