// -------------------------------- lisense -----------------------------
export const category = [
  { option: 'Perpétuelle', value: 'PERPETUELLE' },
  { option: 'Annuelle', value: 'ANNUELLE' },
];
export const type = [
  { value: 'INDIVIDUELLE', option: 'Individuelle' },
  { value: 'COMPAGNIE', option: 'Compagnie' },
];

// -----------------------------  Car ----------------------------------------------------------------
export const carType = [
  { option: 'Berlin', value: 'SEDAN' },
  { option: 'Break', value: 'ESTATE' },
  { option: 'Grand Break', value: 'EXTENDED_ESTATE' },
  { option: 'Monospace', value: 'MONOSPACE' },
  { option: 'Van', value: 'PASSENGER_VAN' },
];
export const carNature = [
  { option: 'Adhèrent', value: 'ADHERENT' },
  { option: 'Client atelier', value: 'WORKSHOP_CUSTOMER' },
  { option: 'Relais', value: 'RELAY' },
  { option: 'Relais loc', value: 'TENANT_RELAY' },
  { option: 'Salarié', value: 'SALARIED' },
  { option: 'Location gérance', value: 'TENANT' },
];
export const carClass = [
  { option: 'Ambulance', value: 'AMBULANCE' },
  { option: 'Taxis', value: 'TAXI' },
  { option: 'VTC', value: 'VTC' },
  { option: 'VAN', value: 'VAN' },
  { option: 'TAD', value: 'TAD' },
];
export const _OPTIONS = [
  { label: 'Attelage < 750kg', value: 0 },
  { label: 'Attelage > 750kg', value: 1 },
  { label: 'Break', value: 2 },
  { label: 'Monospace', value: 3 },
  { label: 'Petit monospace', value: 4 },
  { label: 'Attache caravane', value: 5 },
  { label: 'Berline', value: 6 },
  { label: 'Climatisation', value: 7 },
  { label: 'Amex', value: 8 },
  { label: 'Siège enfant', value: 9 },
  { label: 'Chèques', value: 10 },
  { label: 'Vélo', value: 11 },
];

// --------------------------------- Company -----------------------------------------------
export const CompanyLegalStatus_OPTIONS = [
  { label: 'ASSOCIATION', value: 'ASSOCIATION' },
  { label: 'SELF EMPLOYED', value: 'SELF_EMPLOYED' },
  { label: 'COOPERATIVE', value: 'COOPERATIVE' },
  { label: 'CUMA', value: 'CUMA' },
  { label: 'EARL', value: 'EARL' },
  { label: 'EI', value: 'EI' },
  { label: 'EIRL', value: 'EIRL' },
  { label: 'EURL', value: 'EURL' },
  { label: 'GAEC', value: 'GAEC' },
  { label: 'GEIE', value: 'GEIE' },
  { label: 'GFA', value: 'GFA' },
  { label: 'GIE', value: 'GIE' },
  { label: 'GROUPING', value: 'GROUPING' },
  { label: 'UNDIVIDED', value: 'UNDIVIDED' },
  { label: 'MICRO ENTERPRISE', value: 'MICRO_ENTERPRISE' },
  { label: 'SARL', value: 'SARL' },
  { label: 'SA', value: 'SA' },
  { label: 'SAS', value: 'SAS' },
  { label: 'SASU', value: 'SASU' },
  { label: 'SC', value: 'SC' },
  { label: 'SCA', value: 'SCA' },
  { label: 'SCEA', value: 'SCEA' },
  { label: 'SCEV', value: 'SCEV' },
  { label: 'SCI', value: 'SCI' },
  { label: 'SCIC', value: 'SCIC' },
  { label: 'SCM', value: 'SCM' },
  { label: 'SCL', value: 'SCL' },
  { label: 'SCOP', value: 'SCOP' },
  { label: 'SCP', value: 'SCP' },
  { label: 'SCS', value: 'SCS' },
  { label: 'SDF', value: 'SDF' },
  { label: 'SEL', value: 'SEL' },
  { label: 'SELAFA', value: 'SELAFA' },
  { label: 'SELARL', value: 'SELARL' },
  { label: 'SELAS', value: 'SELAS' },
  { label: 'SELCA', value: 'SELCA' },
  { label: 'SEM', value: 'SEM' },
  { label: 'SEML', value: 'SEML' },
  { label: 'SEP', value: 'SEP' },
  { label: 'SICA', value: 'SICA' },
  { label: 'SNC', value: 'SNC' },
  { label: 'SPFPLA', value: 'SPFPLA' },
  { label: 'STEF', value: 'STEF' },
];
// --------------------------------------- Driver --------------------------------
export const COLOR_OPTIONS = [
  '#4D4D4D',
  '#999999',
  '#FFFFFF',
  '#F44E3B',
  '#FCDC00',
  '#A4DD00',
  '#68CCCA',
  '#AEA1FF',
  '#FDA1FF',
  '#333333',
  '#16A5A5',
  '#009CE0',
  '#000000',
  '#194D33',
  '#0C797D',
  '#AB149E',
];
export const driverNature = [
  { option: 'Adhèrent', value: 'ADHERENT' },
  { option: 'External', value: 'EXTERNAL' },
  { option: 'Artisan', value: 'ARTISAN' },
  { option: 'Salarié', value: 'SALARIED' },
  { option: 'Opendata', value: 'OPENDATA' },
  { option: 'Locataire', value: 'TENANT' },
  { option: 'Adhèrent Locataire', value: 'TENANT_ADHERENT' },
];

export const driverWorkTime = [
  { option: 'H24', value: 'H24' },
  { option: 'jour', value: 'DAY' },
  { option: 'nuit', value: 'NIGHT' },
];
export const priority = [
  { option: 'Premium', value: 'PREMIUM' },
  { option: 'Standard', value: 'STANDARD' },
];
// ----------------------
export const _Civility = [
  { option: 'Non précisé', value: 'NOT_SPECIFIED' },
  { option: 'Monsieur', value: 'SIR' },
  { option: 'Madame', value: 'MADAM' },
  { option: 'Société', value: 'COMPANY' },
];
// ------------------
// ------------------------ Resa & Courses -------------------
//  --------- client --------------
export const CLIENT_ORDER_Nature = [
  { option: 'Une facture par course', value: '1' },
  { option: 'Factures regroupées par numéros de commandes, projets, ...', value: '2' },
  { option: 'Facture unique de toutes les courses', value: '3' },
];
// -------- dispatch ------------DISPATCH_Nature.find((nature) => nature.value === row.nature)

export const DISPATCH_Nature = [
  { option: 'Balnéothérapie', value: 'BT' },
  { option: 'Chimio', value: 'CM' },
  { option: 'Chimiothérapie 1h00', value: 'CT1H00' },
  { option: 'Chimiothérapie 1h30', value: 'CT1H30' },
  { option: 'Chimiothérapie 2h00', value: ' CT2H00' },
  { option: 'Chimiothérapie 3h00', value: ' CT3H00' },
  { option: 'Chimiothérapie 4h00', value: 'CT4H00' },
  { option: 'Chimiothérapie 5h00', value: 'CT5H00' },
  { option: 'Chimiothérapie 6h00', value: 'CT6H00' },
  { option: 'Consultation', value: ' CS' },
  { option: 'Dialyse', value: 'DS' },
  { option: 'Hôpital de jour', value: 'HDJ' },
  { option: 'Hospitalisation', value: 'HP' },
  { option: 'Hospitalisation de jour', value: 'HPDJ' },
  { option: 'Kine', value: 'KN' },
  { option: 'Radiothérapie', value: 'RD' },
  { option: 'Rééducation 1h00', value: 'RED1H00' },
  { option: 'Rééducation 1h30', value: 'RED1H30' },
  { option: 'Rééducation 2h00', value: ' RED2H00' },
  { option: 'Rééducation 3h00', value: ' RED3H00' },
  { option: 'Scanner 1h00', value: ' SC1H00' },
  { option: 'Scanner 2h00', value: ' SC2H00' },
  { option: 'Petscan', value: 'PTS' },
  { option: 'Transfusion 1h00', value: ' TF1H00' },
  { option: 'Transfusion 2h00', value: 'TF2H00' },
  { option: 'Transfusion 4h00', value: ' TF4H00' },
];
export const DISPATCH_PAYMENT_METHOD = [
  { option: 'CPAM', value: 'CPAM' },
  { option: 'Paiement espèce', value: 'CASH' },
  { option: 'CB à Bord', value: 'CARD_ON_BOARD' },
  { option: 'CB via App', value: 'CARD_VIA_APP' },
];
// ----------------------- administration user options --------------------
export const ADMIN_USER_ROLES = [
  { id: 'ADMIN', label: 'Admin' },
  { id: 'USER', label: 'Standardiste' },
];
export const ADMIN_USER_PARAMS = [
  { label: 'Notifications retard course', id: '1' },
  { label: 'Notifications annulation chauffeur', id: '2' },
  { label: 'Notifications nouvelle course', id: '3' },
  { label: 'Notifications nouvelle course Comet', id: '4' },
  { label: 'Notifications nouvelle course Galacsi', id: '5' },
  { label: 'Notifications problème course', id: '6' },
];
export const ABBREVIATION = [
  { option: 'Monsieur', value: 'SIR' },
  { option: 'Madame', value: 'MELLE' },
  { option: 'Mademoiselle', value: 'MADAME' },
];

export const APIOPTIONS = [
  { value: 'default', option: 'Dispatch Pré' },
  { value: 'client', option: 'Immédiate' },
];
