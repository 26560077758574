/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosEntites';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  orderingParties: [],
  orderingParty: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'orderingParty',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET orderingParties
    getOrderingPartiesSuccess(state, action) {
      state.isLoading = false;
      state.orderingParties = action.payload;
    },

    // GET orderingParty
    getOrderingPartySuccess(state, action) {
      state.isLoading = false;
      state.orderingParty = action.payload;
    },
    //  Create orderingParty
    createOrderingPartySuccess(state, action) {
      state.isLoading = false;
      const newOrderingParty = action.payload;
      state.orderingParties = [...state.orderingParties, newOrderingParty];
    },
    // Update orderingParty
    updateOrderingPartySuccess(state, action) {
      state.isLoading = false;
      state.orderingParties = state.orderingParties.map((orderingParty) => {
        if (orderingParty.id === action.payload.id) {
          return action.payload;
        }
        return orderingParty;
      });
    },
    // Delete orderingParty
    deleteOrderingPartySuccess(state, action) {
      state.isLoading = false;
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.orderingParties = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getOrderingPartiesSuccess,
  getOrderingPartySuccess,
  createOrderingPartySuccess,
  updateOrderingPartySuccess,
  deleteOrderingPartySuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getOrderingParties() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ordering_party`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getOrderingPartiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getOrderingPartiesFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ordering_party/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getOrderingParty(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ordering_party/${id}`,
        {
          // Update the API endpoint
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getOrderingPartySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createOrderingParty(orderingParty) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ordering_party`,
        orderingParty,
        {
          // Update the API endpoint
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createOrderingPartySuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateOrderingParty(id, orderingParty) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ordering_party/${id}`,
        orderingParty,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateOrderingPartySuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
