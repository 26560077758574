import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  adminAuthorities: [],
  adminAuthority: null,
};

const adminAuthoritySlice = createSlice({
  name: 'adminAuthority',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAdminAuthoritiesSuccess(state, action) {
      state.isLoading = false;
      state.adminAuthorities = action.payload;
    },
    getAdminAuthoritySuccess(state, action) {
      state.isLoading = false;
      state.adminAuthority = action.payload;
    },
    createAdminAuthoritySuccess(state, action) {
      state.isLoading = false;
      const newAdminAuthority = action.payload;
      state.adminAuthorities = [...state.adminAuthorities, newAdminAuthority];
    },
    updateAdminAuthoritySuccess(state, action) {
      state.isLoading = false;
      state.adminAuthorities = state.adminAuthorities.map((adminAuthority) => {
        if (adminAuthority.id === action.payload.id) {
          return action.payload;
        }
        return adminAuthority;
      });
    },
    updateAdminProfileSuccess(state, action) {
      state.isLoading = false;
    },
    changeAdminPasswordSuccess(state, action) {
      state.isLoading = false;
    },
    deleteAdminAuthoritySuccess(state, action) {
      state.isLoading = false;
      // You can handle deletion logic if needed
    },
  },
});

export default adminAuthoritySlice.reducer;

export const {
  getAdminAuthoritiesSuccess,
  getAdminAuthoritySuccess,
  createAdminAuthoritySuccess,
  updateAdminAuthoritySuccess,
  deleteAdminAuthoritySuccess,
} = adminAuthoritySlice.actions;

export function getAdminAuthorities() {
  return async (dispatch) => {
    dispatch(adminAuthoritySlice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin-authority`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(adminAuthoritySlice.actions.getAdminAuthoritiesSuccess(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(adminAuthoritySlice.actions.hasError(error));
    }
  };
}

export function getAdminAuthority(id) {
  return async (dispatch) => {
    dispatch(adminAuthoritySlice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin-authority/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(adminAuthoritySlice.actions.getAdminAuthoritySuccess(response.data));
    } catch (error) {
      dispatch(adminAuthoritySlice.actions.hasError(error));
    }
  };
}

export function createAdminAuthority(adminAuthority) {
  return async (dispatch) => {
    dispatch(adminAuthoritySlice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin-authority`,
        adminAuthority,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(adminAuthoritySlice.actions.createAdminAuthoritySuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(adminAuthoritySlice.actions.hasError(error));
      return 400;
    }
  };
}

export function updateAdminAuthority(id, adminAuthority) {
  return async (dispatch) => {
    dispatch(adminAuthoritySlice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin-authority/${id}`,
        adminAuthority,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(adminAuthoritySlice.actions.updateAdminAuthoritySuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(adminAuthoritySlice.actions.hasError(error));
      return 400;
    }
  };
}

export function deleteAdminAuthority(id) {
  return async (dispatch) => {
    dispatch(adminAuthoritySlice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin-authority/${id}`
      );
      dispatch(adminAuthoritySlice.actions.deleteAdminAuthoritySuccess(response.data));
    } catch (error) {
      dispatch(adminAuthoritySlice.actions.hasError(error));
    }
  };
}

export function updateAdminProfile(data) {
  return async (dispatch) => {
    dispatch(adminAuthoritySlice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin/update-profile`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(adminAuthoritySlice.actions.updateAdminProfileSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(adminAuthoritySlice.actions.hasError(error));
      return 400;
    }
  };
}

export function ChangeAdminPassword(data) {
  return async (dispatch) => {
    dispatch(adminAuthoritySlice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin/update-password`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(adminAuthoritySlice.actions.changeAdminPasswordSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(adminAuthoritySlice.actions.hasError(error));
      return 400;
    }
  };
}
