import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  selectedReservedTab: 0,
  // selectedReservedTabContent: null,
  restabs: [],
};

const slice = createSlice({
  name: 'reservedTabs',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Tab
    getTabSuccess(state, action) {
      state.isLoading = false;
      state.restabs = action.payload;
    },
    // GET All Tab
    getTabsSuccess(state) {
      state.isLoading = false;
      state.restabs = state.restabs || state.restabs[0];
      state.selectedReservedTab = state.selectedReservedTab || 0;
      const findSelectedReservedTab = state.restabs.find(
        (tab) => tab.id === state.selectedReservedTab
      );
      // state.selectedReservedTabContent = findSelectedReservedTab?.content || state.tabs[0].content;
    },

    // CREATE Tab
    createTabsSuccess(state, action) {
      const newTab = action.payload;
      state.isLoading = false;
      const exists = {};
      state.restabs.forEach((tab) => {
        exists[tab.label] = true;
      });
      const OwnTabCreated = !exists[newTab.label] || newTab.label === 'Création';
      console.log('Existing restabs:', state.restabs);
      console.log('Tab to be added:', newTab);
      if (OwnTabCreated) {
        state.restabs = [...state.restabs, newTab];
        state.selectedReservedTab = state.restabs.length - 1;
        // state.selectedReservedTabContent = newTab.content;
      } else {
        const index = state.restabs.findIndex((obj) => obj.id === newTab.id);
        // const tabId = newTab.id;
        state.selectedReservedTab = index;
      }
    },

    // UPDATE TAB
    updateTabSuccess(state, action) {
      state.isLoading = false;
      const tabId = action.payload;
      state.selectedReservedTab = tabId;
      // state.selectedReservedTabContent = state.tabs[tabId].content;
    },

    // DELETE Tab
    deleteTabSuccess(state, action) {
      const TabId = action.payload;
      let newSelectedReservedTab = state.selectedReservedTab;
      const updatedTabs = state.restabs.filter((_, i) => i !== TabId);
      if (TabId === state.selectedReservedTab) {
        newSelectedReservedTab = Math.max(0, state.selectedReservedTab - 1);
      } else if (TabId < state.selectedReservedTab) {
        newSelectedReservedTab = state.selectedReservedTab - 1;
      }
      state.restabs = updatedTabs.map((tab, i) => ({
        ...tab,
        TabId: i,
      }));
      state.selectedReservedTab = newSelectedReservedTab;
      // state.selectedReservedTabContent = state.tabs[newSelectedReservedTab].content;
    },
    // DELETE All Tabs
    deleteAllTabsSuccess(state) {
      const updatedTabs = state.restabs.toSpliced(1, state.restabs.length);
      state.selectedReservedTab = 0;
      state.restabs = updatedTabs;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  getTabSuccess,
  getTabsSuccess,
  createTabsSuccess,
  updateTabSuccess,
  deleteTabSuccess,
  deleteAllTabsSuccess,
} = slice.actions;
// ----------------------------------------------------------------------

export function getAllTabs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      dispatch(slice.actions.getTabsSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTab(newTab) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.createTabsSuccess(newTab));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTab(TabId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateTabSuccess(TabId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTab(TabId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteTabSuccess(TabId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAllTabs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteAllTabsSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
