import { useState } from 'react';
import { debounce, isArray } from 'lodash';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios'; // Import axios library

RHFCodePostal.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFCodePostal({ name, label, defaultValue, helperText, ...other }) {
  const { control, setValue } = useFormContext();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedAPICall = debounce(async (query) => {
    // const handleSearchApiGouv = async (query) => {
    setLoading(true);
    try {
      // const response = await axios.get(
      //   `https://nominatim.openstreetmap.org/search?q=${query}&countrycodes=fr&format=json`
      //   // `https://happyapi.fr/api/codePostaux?codePostal=${query}`
      // );
      const response = await axios.get(
        `https://geo.api.gouv.fr/communes?codePostal=${query}&fields=departement,codesPostaux&format=json&geometry=centre`
        // `https://happyapi.fr/api/codePostaux?codePostal=${query}`
      );
      debugger;
      const { data } = response;
      console.log('data:', data);
      // if (isArray(data)) {
      // const addressOptions = data?.map((item) => ({
      //   id: item.place_id,
      //   label: item.display_name.replace(/, Metropolitan France,|, France/g, ''),
      //   // id: item.codeCommune,
      //   // label: `${item.codePostal},${item.nomCommune},${item.libelleAcheminement}`,
      // }));
      // 95000, Bords d'Oise, Cergy, Pontoise, Val-d'Oise, Île-de-France, France métropolitaine, France"
      const addressOptions = data?.map((item) => ({
        id: item.code,
        // label: item.nom.replace(/, Metropolitan France,|, France/g, ''),
        label: `${item.code},${item.nom},${item.departement.nom}`,
        departement: item.departement.nom,
        // id: item.codeCommune,
        // label: `${item.codePostal},${item.nomCommune},${item.libelleAcheminement}`,
      }));
      // const addressOptions = response.data;
      setOptions(() => addressOptions);
      // } else {
      //   const dataOptions = [{ id: '1', label: 'Code postal invalid' }];
      //   setOptions(dataOptions);
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, 1000);

  const handleSearch = (event) => {
    const query = event?.target.value;

    debouncedAPICall(query);
  };
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={(option) => (option ? option.label : '')}
          onInputChange={handleSearch}
          onChange={(event, newValue) => {
            const selectedValue = newValue !== null ? newValue : '';
            console.log('selected value code postal:', selectedValue);
            setValue(name, selectedValue, { shouldValidate: true });
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              fullWidth
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          renderOption={(props, option, index) => {
            const key = `listItem-${index}-${option.id}`;

            return (
              <li {...props} key={key}>
                {option.label}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          {...other}
        />
      )}
    />
  );
}
