//
import _mock from '../_mock';

// ----------------------------------------------------------------------

export const _analyticPost = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: _mock.text.title(index),
  description: _mock.text.description(index),
  image: _mock.image.cover(index),
  postedAt: _mock.time(index),
}));

export const _analyticOrderTimeline = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    '1983, orders, $4220',
    '12 Invoices have been paid',
    'Order #37745 from September',
    'New order placed #XF-2356',
    'New order placed #XF-2346',
  ][index],
  type: `order${index + 1}`,
  time: _mock.time(index),
}));

export const _analyticTraffic = [
  {
    value: 'facebook',
    label: 'Libre',
    total: 20,
  },
  {
    value: 'google',
    label: 'En service',
    total: 10,
  },
  {
    value: 'linkedin',
    label: 'Déconnecté',
    total: 15,
  },
  {
    value: 'twitter',
    label: 'Indisponible',
    total: 32,
  },
];
