import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Button,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  TextField,
} from '@mui/material';
import Iconify from '../iconify/Iconify';
import RHFTextField from './RHFTextField';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  defaultValue: PropTypes.bool,
};

export function RHFCheckbox({ name, defaultValue, helperText, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={field.value || false}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  helperText: PropTypes.node,
};

export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, ...other }) {
  const { control } = useFormContext();
  /* eslint-disable */

  const getSelected = (selectedItems, newItem) => {
    const existingIndex = selectedItems.findIndex((item) => item.id === newItem);

    if (existingIndex !== -1) {
      // If the item already exists, create a new array without that item
      return selectedItems.filter((item) => item.id !== newItem);
    } else {
      // If the item doesn't exist, add it to the array
      const selectedItem = options.find((option) => option.id === newItem);
      return [...selectedItems, selectedItem];
    }
  };

  function isIdInArray(id, array) {
    return array.some((item) => item.id === id);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              ...(row && {
                flexDirection: 'row',
              }),
              '& .MuiFormControlLabel-root': {
                '&:not(:last-of-type)': {
                  mb: spacing || 0,
                },
                ...(row && {
                  mr: 0,
                  '&:not(:last-of-type)': {
                    mr: spacing || 2,
                  },
                }),
              },
            }}
          >
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      sx={{ alignSelf: 'start' }}
                      checked={isIdInArray(option.id, field.value)}
                      onChange={() => {
                        field?.onChange(getSelected(field.value, option.id));
                      }}
                    />
                  }
                  label={option.label}
                  {...other}
                />
              );
            })}
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
