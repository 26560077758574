import PropTypes from 'prop-types';
// form
import { useFormContext, Controller, InputAdornment } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
// Icons

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  startAdornment: PropTypes.bool,
  endAdornment: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  sx: PropTypes.object,
  multiLine: PropTypes.bool,
  rowsNumber: PropTypes.number,
  validationFunc: PropTypes.func,
};

export default function RHFTextField({
  name,
  helperText,
  startAdornment,
  endAdornment,
  disabled,
  type,
  inputProps,
  sx,
  validationFunc,
  multiLine,
  rowsNumber,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: validationFunc }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={disabled || false}
          {...field}
          fullWidth
          multiline={multiLine || false}
          rows={rowsNumber || 'default'}
          sx={sx}
          type={type && type}
          value={field.value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          itemprops={inputProps}
          {...other}
        />
      )}
    />
  );
}
