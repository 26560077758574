import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import {
  Badge,
  Stack,
  Typography,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
} from '@mui/material';
// components
import { CustomAvatar, CustomAvatarGroup } from '../../../../components/custom-avatar';
import BadgeStatus from '../../../../components/badge-status';

// Array Chat users
import _mock from '../../../../_mock';

// ----------------------------------------------------------------------

const CURRENT_USER_ID = 1;

ChatNavItem.propTypes = {
  openNav: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  conversation: PropTypes.object,
};

export default function ChatNavItem({ conversation, openNav, isSelected, onSelect }) {
  const isSenderAdmin = conversation.sender.id === 1;
  const details = getDetails(conversation, CURRENT_USER_ID);

  // const lastActivity = conversation.messages[conversation.messages.length - 1].createdAt;

  // const isGroup = details.otherParticipants.length > 1;

  // const isUnread = conversation.unreadCount > 0;

  // const hasOnlineInGroup =
  //   isGroup && details.otherParticipants.map((item) => item.status).includes('online');
  // const index = useMemo(() => Math.floor(Math.random() * 10), []);
  return (
    <ListItemButton
      disableGutters
      onClick={onSelect}
      sx={{
        py: 1.5,
        px: 2.5,
        ...(isSelected && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        {/* <CustomAvatar
          key={details.otherParticipants[0].id}
          alt={details.otherParticipants[0].name}
          src={details.otherParticipants[0].avatar}
          src={_mock.image.avatar(index)}
          BadgeProps={{
            badgeContent: <BadgeStatus status={details.otherParticipants[0].status} />,
          }}
          sx={{ width: 48, height: 48 }}
        /> */}
        <CustomAvatar
          src={`https://file-uploaded.s3.eu-west-3.amazonaws.com/${
            isSenderAdmin ? conversation?.receiver.picture : conversation?.sender.picture
          }`}
          alt="picture"
        />
      </ListItemAvatar>

      {/* {openNav && ( */}
      <>
        {/* <ListItemText
          primary={details.displayNames}
          primary="cdcdcdcdceeee"
          primaryTypographyProps={{ noWrap: true, variant: 'subtitle2' }}
          secondary="ddddddddddddddd"
          secondary={
            isSenderAdmin ? conversation?.receiver.lastname : conversation?.sender.lastname
          }
          secondaryTypographyProps={{
            noWrap: true,
            variant: isUnread ? 'subtitle2' : 'body2',
            color: isUnread ? 'text.primary' : 'text.secondary',
          }}
          /> */}

        <Stack
        // sx={{ ml: 2, height: 44 }}
        >
          <Typography
            // noWrap
            // variant="body2"
            component="span"
            sx={{
              // mb: 1.5,
              fontSize: 12,
              // color: 'text.disabled',
            }}
          >
            {/* {formatDistanceToNowStrict(new Date(lastActivity), {
                addSuffix: false,
              })} */}
            {isSenderAdmin ? conversation?.receiver.lastname : conversation?.sender.lastname}{' '}
            {isSenderAdmin ? conversation?.receiver.firstname : conversation?.sender.firstname}
          </Typography>
          <Typography
            sx={{
              // mb: 1.5,
              fontSize: 12,
              color: 'text.disabled',
            }}
          >
            Last message
          </Typography>

          {/* {isUnread && <BadgeStatus status="unread" size="small" />} */}
        </Stack>
      </>
      {/* )} */}
    </ListItemButton>
  );
  // return (
  //   <div>
  //     <p>{conversation?.id}</p>
  //   </div>
  // );
}

// ----------------------------------------------------------------------

const getDetails = (conversation, currentUserId) => {
  // const otherParticipants = conversation.participants.filter(
  //   (participant) => participant.id !== currentUserId
  // );

  // const displayNames = otherParticipants.map((participant) => participant.name).join(', ');

  let displayText = '';

  // const lastMessage = conversation.messages[conversation.messages.length - 1];
  const lastMessage = null;
  if (lastMessage) {
    const sender = lastMessage.sender.id === 1 ? 'You: ' : '';

    const message = lastMessage.content;

    displayText = `${sender}${message}`;
  }
  return { displayText };
};
