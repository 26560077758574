/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosEntites';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  rayons: [],
  rayon: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'rayon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET favoriteAddresss
    getRayonsSuccess(state, action) {
      state.isLoading = false;
      state.rayons = action.payload;
    },

    // GET favoriteAddress
    getRayonSuccess(state, action) {
      state.isLoading = false;
      state.rayon = action.payload;
    },
    //  Create favoriteAddress
    createRayonSuccess(state, action) {
      state.isLoading = false;
      const newRayon = action.payload;
      state.rayons = [...state.rayons, newRayon];
    },
    // Update favoriteAddress
    updateRayonSuccess(state, action) {
      state.isLoading = false;
      state.rayons = state.rayons.map((rayon) => {
        if (rayon.id === action.payload.id) {
          return action.payload;
        }
        return rayon;
      });
    },
    // Delete favoriteAddress
    deleteRayonSuccess(state, action) {
      state.isLoading = false;
      const rayonId = action.payload;
      state.rayons = state.rayons.filter((event) => event.id !== rayonId);
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.rayons = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getRayonsSuccess,
  getRayonSuccess,
  createRayonSuccess,
  updateRayonSuccess,
  deleteRayonSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getRayons() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ride-radius`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getRayonsSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getRayonsFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ride-radius/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getRayon(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ride-radius/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getRayonSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createRayon(airportStation) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ride-radius`,
        airportStation,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createRayonSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateRayon(id, airportStation) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ride-radius/${id}`,
        airportStation,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateRayonSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteRayon(rayonId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/ride-radius/${rayonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteRayonSuccess(rayonId));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
