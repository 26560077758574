import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTrips: [],
};

const selectedTripsSlice = createSlice({
  name: 'selectedTrips',
  initialState,
  reducers: {
    setSelectedTrips(state, action) {
      state.selectedTrips = action.payload;
    },
    clearSelectedTrips(state) {
      state.selectedTrips = [];
    },
    addSelectedTrip(state, action) {
      const trip = action.payload;
      state.selectedTrips = [...state.selectedTrips, trip];
    },
    addAllSelectedTrip(state, action) {
      const trip = action.payload;
      state.selectedTrips = [...state.selectedTrips, trip];
    },
    removeSelectedTrip(state, action) {
      const trip = action.payload;
      state.selectedTrips = state.selectedTrips.filter(
        (selectedTrip) => selectedTrip.id !== trip.id
      );
    },
  },
});

export const {
  setSelectedTrips,
  clearSelectedTrips,
  addSelectedTrip,
  removeSelectedTrip,
  addAllSelectedTrip,
} = selectedTripsSlice.actions;
export default selectedTripsSlice.reducer;
