/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  licenseDocuments: [],
  licenseDocument: null,
};

const slice = createSlice({
  name: 'licenseDocument',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getLicenseDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.licenseDocuments = action.payload;
    },
    getLicenseDocumentSuccess(state, action) {
      state.isLoading = false;
      state.licenseDocument = action.payload;
    },
    createLicenseDocumentSuccess(state, action) {
      state.isLoading = false;
      const newlicenseDocument = action.payload;
      state.licenseDocuments = [...state.licenseDocuments, newlicenseDocument];
    },
    updateLicenseDocumentSuccess(state, action) {
      state.isLoading = false;
      state.licenseDocuments = state.licenseDocuments.map((licenseDoc) => {
        if (licenseDoc.id === action.payload.id) {
          return action.payload;
        }
        return licenseDoc;
      });
    },
    deleteLicenseDocumentSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const {
  getLicenseDocumentSuccess,
  getLicenseDocumentsSuccess,
  createLicenseDocumentSuccess,
  updateLicenseDocumentSuccess,
  deleteLicenseDocumentSuccess,
} = slice.actions;

export function getLicenseDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license-document`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      console.log(response.data);
      dispatch(slice.actions.getLicenseDocumentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLicenseDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license-document/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getLicenseDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createLicenseDocument(licenseDocument) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license-document`,
        licenseDocument,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createLicenseDocumentSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateLicenseDocument(id, licenseDocument) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license-document/${id}`,
        licenseDocument,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateLicenseDocumentSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteLicenseDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license-document/${id}`
      );
      dispatch(slice.actions.deleteLicenseDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
