import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  returnSelectedTrip: [],
};

const returnSelectedTripSlice = createSlice({
  name: 'returnSelectedTrip',
  initialState,
  reducers: {
    setReturnSelectedTrip(state, action) {
      state.returnSelectedTrip = action.payload;
    },
    clearReturnSelectedTrip(state) {
      state.returnSelectedTrip = [];
    },
    addReturnSelectedTrip(state, action) {
      const trip = action.payload;
      state.returnSelectedTrip = [...state.returnSelectedTrip, trip];
    },
    addAllReturnSelectedTrip(state, action) {
      const trips = action.payload;
      state.returnSelectedTrip = [...state.returnSelectedTrip, ...trips];
    },
    removeReturnSelectedTrip(state, action) {
      const tripIdToRemove = action.payload;
      state.returnSelectedTrip = state.returnSelectedTrip.filter(
        (selectedTrip) => selectedTrip.id !== tripIdToRemove
      );
    },
  },
});

export const {
  setReturnSelectedTrip,
  clearReturnSelectedTrip,
  addReturnSelectedTrip,
  addAllReturnSelectedTrip,
  removeReturnSelectedTrip,
} = returnSelectedTripSlice.actions;
export default returnSelectedTripSlice.reducer;
