import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack, Drawer, IconButton, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// utils
import axios from '../../../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
//
import ChatNavList from './ChatNavList';
import ChatNavSearch from './ChatNavSearch';
import ChatNavAccount from './ChatNavAccount';
import ChatNavSearchResults from './ChatNavSearchResults';
import { getContacts } from '../../../../redux/slices/chat';
import { useDispatch, useSelector } from '../../../../redux/store';
import { useAuthContext } from '../../../../auth/useAuthContext';
import { CustomAvatar } from '../../../../components/custom-avatar';
import BadgeStatus from '../../../../components/badge-status/BadgeStatus';

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => <IconButton disableRipple {...props} />)(
  ({ theme }) => ({
    left: 0,
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    top: theme.spacing(13),
    borderRadius: `0 12px 12px 0`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.darker,
    },
  })
);

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;

ChatNavDrawer.propTypes = {
  //   conversations: PropTypes.object,
  //   activeConversationId: PropTypes.string,
  setSelectedContact: PropTypes.func,
  selectedContact: PropTypes.object,
  setOpenDrawer: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ChatNavDrawer({
  //   conversations,
  //   activeConversationId,
  selectedContact,
  setSelectedContact,
  setOpenDrawer,
  open,
  onClose,
}) {
  const theme = useTheme();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'md');
  //   const [selectedContact, setSelectedContact] = useState(null);
  const { contacts, recipients, participants, activeConversationId } = useSelector(
    (state) => state.chat
  );

  const [openNav, setOpenNav] = useState(false);

  const [searchResults, setSearchResults] = useState([]);

  const [searchContacts, setSearchContacts] = useState('');

  const isCollapse = isDesktop && !openNav;

  useEffect(() => {
    if (!isDesktop) {
      handleCloseNav();
    } else {
      handleOpenNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const handleToggleNav = () => {
    setOpenNav(!openNav);
  };

  const handleOpenNav = () => {
    setOpenNav(true);
  };

  const handleCloseNav = () => {
    setOpenNav(false);
  };

  const handleChangeSearch = async (event) => {
    try {
      const { value } = event.target;

      setSearchContacts(value);

      if (value) {
        const response = await axios.get('/api/chat/search', {
          params: { query: value },
        });

        setSearchResults(response.data.results);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectContact = (result) => {
    setSearchContacts('');
    navigate(PATH_DASHBOARD.chat.view(result.username));
  };

  const renderContent = (
    <>
      <Box sx={{ p: 2.5 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          {!isCollapse && (
            <>
              <ChatNavAccount />
              <Box sx={{ flexGrow: 1 }} />
            </>
          )}

          <IconButton onClick={handleToggleNav}>
            <Iconify icon={openNav ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'} />
          </IconButton>

          {/* {!isCollapse && (
            <IconButton component={RouterLink} to={PATH_DASHBOARD.chat.new}>
              <Iconify icon="eva:edit-fill" />
            </IconButton>
          )} */}
        </Stack>

        {/* {!isCollapse && (
          <ChatNavSearch
            value={searchContacts}
            onChange={handleChangeSearch}
            onClickAway={() => setSearchContacts('')}
          />
        )} */}
      </Box>

      <Scrollbar>
        {!searchContacts ? (
          <ChatNavList
            openNav={openNav}
            onCloseNav={handleCloseNav}
            conversations={contacts}
            selected={(conversationId) => selectedContact?.id === conversationId}
            // selected={()}
            setSelectedContact={setSelectedContact}
            setOpenDrawer={setOpenDrawer}
          />
        ) : (
          <ChatNavSearchResults
            searchContacts={searchContacts}
            searchResults={searchResults}
            onSelectContact={handleSelectContact}
          />
        )}
      </Scrollbar>
    </>
  );
  useEffect(() => {
    // dispatch(getConversations());
    dispatch(getContacts());
  }, [dispatch]);
  console.log('uuuuuuuuuuuuuser', user);
  return (
    <>
      {/* {!isDesktop && (
        <StyledToggleButton onClick={handleToggleNav}>
          <Iconify width={16} icon="eva:people-fill" />
        </StyledToggleButton>
      )}

      {isDesktop ? (
        <Drawer
          open={openNav}
          variant="persistent"
          PaperProps={{
            sx: {
              pb: 1,
              width: 1,
              position: 'static',
              ...(isCollapse && {
                transform: 'none !important',
                visibility: 'visible !important',
              }),
            },
          }}
          sx={{
            width: NAV_WIDTH,
            transition: theme.transitions.create('width'),
            ...(isCollapse && {
              width: NAV_COLLAPSE_WIDTH,
            }),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={handleCloseNav}
          ModalProps={{ keepMounted: true }}
          PaperProps={{
            sx: {
              pb: 1,
              width: NAV_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )} */}
      <Drawer open={open} onClose={onClose} anchor="right">
        <div style={{ position: 'relative' }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 1,
              top: 1,
            }}
          >
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </div>
        {/* Account */}
        <div
          style={{
            marginTop: 10,
            padding: '20px 10px',
            display: 'flex',
          }}
        >
          <CustomAvatar
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt={user?.firstname}
            name={user?.firstname}
            BadgeProps={{
              badgeContent: <BadgeStatus status="online" />,
            }}
            // onClick={handleOpenPopover}
            sx={{ cursor: 'pointer', width: 48, height: 48 }}
          />
          <div
            style={{
              marginLeft: 8,
            }}
          >
            <Typography noWrap variant="subtitle2">
              {user?.firstname} {user?.lastname}
            </Typography>

            <Typography noWrap variant="body2" sx={{ color: 'text.secondary' }}>
              {user?.email}
            </Typography>
          </div>
        </div>
        {/* <ChatNavAccount /> */}
        <ChatNavList
          openNav={openNav}
          onCloseNav={handleCloseNav}
          conversations={contacts}
          selected={(conversationId) => selectedContact?.id === conversationId}
          // selected={()}
          setSelectedContact={setSelectedContact}
          setOpenDrawer={setOpenDrawer}
          sx={{
            minWidth: 400,
            marginTop: 4,
          }}
        />
      </Drawer>
    </>
  );
}
