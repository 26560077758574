/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosEntites';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  zones: [],
  zone: null,
};

const slice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Zones
    getZonesSuccess(state, action) {
      state.isLoading = false;
      state.zones = action.payload;
    },

    // GET Zone
    getZoneSuccess(state, action) {
      state.isLoading = false;
      state.zone = action.payload;
    },
    //  Create Zone
    createZoneSuccess(state, action) {
      state.isLoading = false;
      const newZone = action.payload;
      state.zones = [...state.zones, newZone];
    },
    // Update Zone
    updateZoneSuccess(state, action) {
      state.isLoading = false;
      state.zones = state.zones.map((Zone) => {
        if (Zone.id === action.payload.id) {
          return action.payload;
        }
        return Zone;
      });
    },
    // Delete Zone
    deleteZoneSuccess(state, action) {
      state.isLoading = false;
      const zoneId = action.payload;
      state.zones = state.zones.filter((event) => event.id !== zoneId);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getZonesSuccess,
  getZoneSuccess,
  createZoneSuccess,
  updateZoneSuccess,
  deleteZoneSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getZones() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_SETUP}/api/v1/sector`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      dispatch(slice.actions.getZonesSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getZone(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/sector/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getZoneSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createZone(zone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/sector`,
        zone,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createZoneSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateZone(id, Zone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/sector/${id}`,
        Zone,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateZoneSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteZone(zoneId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/sector/${zoneId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteZoneSuccess({ zoneId }));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
