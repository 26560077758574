import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reservedSelectedTrip: [],
};

const reservedSelectedTripSlice = createSlice({
  name: 'reservedSelectedTrip',
  initialState,
  reducers: {
    setReservedSelectedTrip(state, action) {
      state.reservedSelectedTrip = action.payload;
    },
    clearReservedSelectedTrip(state) {
      state.reservedSelectedTrip = [];
    },
    addReservedSelectedTrip(state, action) {
      const trip = action.payload;
      state.reservedSelectedTrip = [...state.reservedSelectedTrip, trip];
    },
    addAllReservedSelectedTrip(state, action) {
      const trip = action.payload;
      state.reservedSelectedTrip = [...state.reservedSelectedTrip, trip];
    },
    removeReservedSelectedTrip(state, action) {
      const trip = action.payload;
      state.reservedSelectedTrip = state.reservedSelectedTrip.filter(
        (selectedTrip) => selectedTrip.id !== trip.id
      );
    },
  },
});
export const {
  setReservedSelectedTrip,
  clearReservedSelectedTrip,
  addReservedSelectedTrip,
  addAllReservedSelectedTrip,
  removeReservedSelectedTrip,
} = reservedSelectedTripSlice.actions;
export default reservedSelectedTripSlice.reducer;
