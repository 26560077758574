import { useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import Iconify from '../iconify/Iconify';

RHFAddressAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  helperText: PropTypes.node,
  validationFunc: PropTypes.func,
};

export default function RHFAddressAutocomplete({
  name,
  label,
  defaultValue,
  helperText,
  validationFunc,
  ...other
}) {
  const { control, setValue } = useFormContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedAPICall = debounce(async (query) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${query}`);
      const data = await response.json();

      const addresses = data.features.map((address) => ({
        id: address.properties.id,
        label: address.properties.label,
        longitude: address.geometry.coordinates[0],
        latitude: address.geometry.coordinates[1],
        postCode: address.properties.postcode,
        street: address.properties.street,
        city: address.properties.city,
      }));

      setOptions(addresses);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleSearch = (event) => {
    const query = event?.target.value;
    setSearchQuery(query);
    debouncedAPICall(query);
  };
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: validationFunc }}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={(option) => (option ? option.label : '')}
          onInputChange={handleSearch}
          onChange={(event, newValue) => {
            const selectedValue = newValue !== null ? newValue : '';

            setValue(name, selectedValue, { shouldValidate: true });
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              fullWidth
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          renderOption={(props, option, index) => {
            const key = `listItem-${index}-${option.id}`;

            return (
              <li {...props} key={key}>
                <Iconify
                  icon="mdi:map-marker"
                  width={15}
                  color={theme.palette.error.main}
                  marginRight={2}
                />{' '}
                {option.label}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          {...other}
        />
      )}
    />
  );
}
