import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  driversStatus: [],
  driverStatus: null,
  positionningHistory: [],
};

const slice = createSlice({
  name: 'driverStatus',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET driversStatus
    getDriversStatusSuccess(state, action) {
      state.isLoading = false;
      state.driversStatus = action.payload;
    },

    // GET driverStatus
    getDriverStatusSuccess(state, action) {
      state.isLoading = false;
      state.driverStatus = action.payload;
    },
    //  Create driverStatus
    createDriverStatusSuccess(state, action) {
      state.isLoading = false;
      const newDriverStatus = action.payload;
      state.drivesStatus = [...state.driversStatus, newDriverStatus];
    },
    // Update driverStatus
    updateDriverStatusSuccess(state, action) {
      state.isLoading = false;
      state.driversStatus = state.driversStatus.map((driverStatus) => {
        if (driverStatus.id === action.payload.id) {
          return action.payload;
        }
        return driverStatus;
      });
    },
    // Delete driverStatus
    deleteDriverStatusSuccess(state, action) {
      state.isLoading = false;
    },
    getDriverPositionningHistorySuccess(state, action) {
      state.isLoading = false;
      state.positionningHistory = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getDriversStatusSuccess,
  getDriverStatusSuccess,
  createDriverStatusSuccess,
  updateDriverStatusSuccess,
  deleteDriverStatusSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
/*eslint-disable*/
export function getDriversStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('driverStatus/get-all');
      dispatch(slice.actions.getDriversStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDriverStatus(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`driverStatus/get-by-id/${id}`);
      dispatch(slice.actions.getDriverStatusSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createDriverStatus(driverStatus) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('driverStatus/add', driverStatus);
      dispatch(slice.actions.createDriverStatusSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateDriverStatus(id, driverStatus) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`driverStatus/${id}`, driverStatus);
      dispatch(slice.actions.updateDriverStatusSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDriverPositionningHistory(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get(`/api/v1/location/${id}/${data.from}/${data.to}`);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/location/${id}/${data.from}/${data.to}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getDriverPositionningHistorySuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
