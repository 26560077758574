import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  licenses: [],
  license: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Licenses
    getLicensesSuccess(state, action) {
      state.isLoading = false;
      state.licenses = action.payload;
    },

    getLicensesSuccesscom(state, action) {
      state.isLoading = false;
      state.licenses = action.payload;
    },
    // GET license
    getLicenseSuccess(state, action) {
      state.isLoading = false;
      state.license = action.payload;
    },
    //  Create license
    createLicenseSuccess(state, action) {
      state.isLoading = false;
      const newLicense = action.payload;
      state.licenses = [...state.licenses, newLicense];
    },
    // Update license
    updateLicenseSuccess(state, action) {
      state.isLoading = false;
      state.licenses = state.licenses.map((license) => {
        if (license.id === action.payload.id) {
          return action.payload;
        }
        return license;
      });
    },
    // Delete license
    deleteLicenseSuccess(state, action) {
      state.isLoading = false;
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.licenses = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getLicensesSuccess,
  getLicensesSuccesscom,
  getLicenseSuccess,
  createLicenseSuccess,
  updateLicenseSuccess,
  deleteLicenseSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

/*eslint-disable*/
export function getLicenses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/get-all`
      );
      dispatch(slice.actions.getLicensesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getLicensesFiltredData(data, filtredObject) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getLicensesByCompany(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/find-by-company/${id}`
      );
      dispatch(slice.actions.getLicensesSuccesscom(response.data));
      console.log('888', response.data);
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCommunes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch('https://geo.api.gouv.fr/communes');
      const data = await response.json();
      console.log('data', data);
      // dispatch(slice.actions.getLicensesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLicense(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/get-by-id/${id}`
      );
      dispatch(slice.actions.getLicenseSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createLicense(license) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/add`,
        license
      );
      dispatch(slice.actions.createLicenseSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateLicense(id, license) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/${id}`,
        license
      );
      dispatch(slice.actions.updateLicenseSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
