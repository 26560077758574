import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      authoritiesuser: action.payload.authoritiesuser,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      authoritiesuser: action.payload.authoritiesuser,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      authoritiesuser: action.payload.authoritiesuser,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      authoritiesuser: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const access_token = storageAvailable ? localStorage.getItem('access_token') : '';
      const refresh_token = localStorage.getItem('refresh_token');
      if (access_token && isValidToken(access_token)) {
        setSession(access_token, refresh_token);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/admin/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        );
        const user = response.data;
        const authoritiesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin/authority/${user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        );
        const authoritiesuser = authoritiesResponse.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
            authoritiesuser,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
            authoritiesuser: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
          authoritiesuser: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (username, password) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/auth/admin-sign-in`,
        {
          username,
          password,
        }
      );
      const { access_token, user, refresh_token } = response.data;
      console.log(refresh_token, access_token);
      if (access_token) {
        setSession(access_token, refresh_token);
        initialize();
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });
      } else {
        console.error('Access token is null. Unable to log in.');
      }
    },
    [initialize]
  );

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { access_token, user } = response.data;

    localStorage.setItem('access_token', access_token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);
  // const axiosInstance = axios.create({
  //   baseURL: process.env.REACT_APP_API_URL_COMPANY,
  // });

  // // Add a request interceptor to include the access token in the headers
  // axiosInstance.interceptors.request.use(
  //   (config) => {
  //     const access_token = localStorage.getItem('access_token');
  //     if (access_token) {
  //       config.headers['Authorization'] = `Bearer ${access_token}`;
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  // // Function to refresh the access token
  // const refreshAccessToken = async () => {
  //   try {
  //     const refresh_token = localStorage.getItem('refresh_token');
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/auth/refresh-token`,
  //       { refresh_token }
  //     );
  //     const { access_token } = response.data;
  //     if (access_token) {
  //       localStorage.setItem('access_token', access_token);
  //       return access_token;
  //     } else {
  //       throw new Error('Failed to refresh access token');
  //     }
  //   } catch (error) {
  //     console.error('Failed to refresh access token:', error);
  //     throw error;
  //   }
  // };

  // // Add a response interceptor to handle token expiration
  // axiosInstance.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   async (error) => {
  //     const originalRequest = error.config;
  //     if (error.response.status === 401 && !originalRequest._retry) {
  //       originalRequest._retry = true;
  //       try {
  //         const access_token = await refreshAccessToken();
  //         originalRequest.headers.Authorization = `Bearer ${access_token}`;
  //         return axiosInstance(originalRequest);
  //       } catch (error) {
  //         // If refresh token also expired or refresh failed, logout the user
  //         console.error('Unable to refresh token. Logging out...');
  //         // Call your logout function here
  //       }
  //     }
  //     return Promise.reject(error);
  //   }
  // );
  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      authoritiesuser: state.authoritiesuser,
      method: 'jwt',
      login,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.authoritiesuser,
      login,
      logout,
      register,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
