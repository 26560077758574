import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosResa';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  coursesPending: [],
  reservedTrips: [],
  finishedTrips: [],
  returnTrips: [],
  course: null,
  totalCount: 0,
};
function orderTrips(array) {
  const statusValues = [
    'ERROR',
    'NOT_CONFIRMED',
    'PENDING',
    'READY',
    'DRIVER_ON_WAY',
    'ARRIVED',
    'IN_PROGRESS',
  ];
  const typeValues = ['PROPOSITION', 'ONE_CLICK', 'PRE_PREFORM', 'CLIENT', 'NOT_SPECIFIED'];

  // Function to get the priority index for status
  function getStatusPriorityIndex(status) {
    return statusValues.indexOf(status);
  }

  // Function to get the priority index for type
  function getTypePriorityIndex(type) {
    return typeValues.indexOf(type);
  }

  // Function to compare objects based on status, type, and trip date
  function compareObjects(obj1, obj2) {
    const statusComparison =
      getStatusPriorityIndex(obj1.status) - getStatusPriorityIndex(obj2.status);
    if (statusComparison !== 0) {
      return statusComparison;
    }

    const typeComparison = getTypePriorityIndex(obj1.type) - getTypePriorityIndex(obj2.type);
    if (typeComparison !== 0) {
      return typeComparison;
    }

    return new Date(obj1.tripDate) - new Date(obj2.tripDate);
  }

  return array.sort(compareObjects);
}
const slice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET courses
    getCoursesSuccess(state, action) {
      state.isLoading = false;
      state.coursesPending = action.payload;
    },
    // GET reserved trips
    getReservedTripsSuccess(state, action) {
      state.isLoading = false;
      state.reservedTrips = action.payload;
    },
    // GET return trips
    getReturnTripsSuccess(state, action) {
      state.isLoading = false;
      state.returnTrips = action.payload;
    },
    // GET finished trips
    getFinisedTripsSuccess(state, action) {
      state.isLoading = false;
      state.finishedTrips = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
    addNewTripToPendingCourses(state, action) {
      state.coursesPending.unshift(action.payload);
      // console.log('sssssssss', state.coursesPending);
    },
    // GET course
    getCourseSuccess(state, action) {
      state.isLoading = false;
      state.course = action.payload;
    },
    assignDriverSuccess(state, action) {
      state.isLoading = false;
    },
    // GET address
    getAddressesSuccess(state, action) {
      state.isLoading = false;
      state.addressesList = action.payload;
    },
    //  Create course
    createCourseSuccess(state, action) {
      state.isLoading = false;
      const newcourse = action.payload;
      state.coursesPending = [...state.coursesPending, newcourse];
      // switch (action.payload.status) {
      //   case 'PENDING':
      //     state.coursesPending = [...state.coursesPending, newcourse];
      //     break;
      //   case 'Completed':
      //     state.coursesPending = [...state.coursesPending, newcourse];
      //     break;

      //   case 'DRIVER_ON_WAY':
      //     state.coursesPending = [...state.coursesPending, newcourse];
      //     break;
      //   case 'IN_PROGRESS':
      //     state.coursesPending = [...state.coursesPending, newcourse];
      //     break;
      //   default:
      // }
    },
    // Update course
    updateCourseSuccess(state, action) {
      state.isLoading = false;
      state.coursesPending = state.coursesPending.map((course) => {
        if (course.id === action.payload.id) {
          return action.payload;
        }
        return course;
      });
    },
    // Delete course
    deleteCourseSuccess(state, action) {
      state.isLoading = false;
    },
    delayCourseSuccess(state, action) {
      state.isLoading = false;
    },

    addTripToPendingCourses(state, action) {
      state.coursesPending.unshift(action.payload);
      state.coursesPending = orderTrips(state.coursesPending);
    },
    updateTripInPendingCourses(state, action) {
      const updatedTripIndex = state.coursesPending.findIndex(
        (trip) => trip.id === action.payload.id
      );
      if (updatedTripIndex !== -1) {
        state.coursesPending[updatedTripIndex] = action.payload;
        state.coursesPending = orderTrips(state.coursesPending);
      }
    },
    deleteTripFromPendingCourses(state, action) {
      state.coursesPending = state.coursesPending.filter((trip) => trip.id !== action.payload);
      state.coursesPending = orderTrips(state.coursesPending);
    },

    addTripToReservedtrips(state, action) {
      state.reservedTrips.unshift(action.payload);
      state.reservedTrips = orderTrips(state.reservedTrips);
    },
    updateTripInReservedtrips(state, action) {
      const updatedTripIndex = state.reservedTrips.findIndex(
        (trip) => trip.id === action.payload.id
      );
      if (updatedTripIndex !== -1) {
        state.reservedTrips[updatedTripIndex] = action.payload;
        state.reservedTrips = orderTrips(state.reservedTrips);
      }
    },
    deleteTripFromReservedtrips(state, action) {
      state.reservedTrips = state.reservedTrips.filter((trip) => trip.id !== action.payload);
      state.reservedTrips = orderTrips(state.reservedTrips);
    },
    addTripToReturnTrips(state, action) {
      state.returnTrips.unshift(action.payload);
      state.returnTrips = orderTrips(state.returnTrips);
    },
    updateTripInReturnTrips(state, action) {
      const updatedTripIndex = state.returnTrips.findIndex((trip) => trip.id === action.payload.id);
      if (updatedTripIndex !== -1) {
        state.returnTrips[updatedTripIndex] = action.payload;
        state.returnTrips = orderTrips(state.returnTrips);
      }
    },
    deleteTripFromReturnTrips(state, action) {
      state.returnTrips = state.returnTrips.filter((trip) => trip.id !== action.payload);
      state.returnTrips = orderTrips(state.returnTrips);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCoursesSuccess,
  getCourseSuccess,
  delayCourseSuccess,
  addNewTripToPendingCourses,
  createCourseSuccess,
  updateCourseSuccess,
  deleteCourseSuccess,
  assignDriverSuccess,
  addTripToPendingCourses,
  updateTripInPendingCourses,
  deleteTripFromPendingCourses,
  addTripToReservedtrips,
  updateTripInReservedtrips,
  deleteTripFromReservedtrips,
  getReturnTripsSuccess,
  addTripToReturnTrips,
  updateTripInReturnTrips,
  deleteTripFromReturnTrips,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCourses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/get-all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getCoursesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
export function createdTrip(tripData) {
  return async (dispatch) => {
    // Dispatch the addNewTripToPendingCourses action with the tripData
    addNewTripToPendingCourses(tripData);
  };
}
// ----------------------------------------------------------------------
export function getReservedTrips() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/reserved-trip`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getReservedTripsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
// ----------------------------------------------------------------------
export function getReturnTrips() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/return-trip`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getReturnTripsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
// ----------------------------------------------------------------------
export function getFinishedTrips(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/completed-trip`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFinisedTripsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
// ----------------------------------------------------------------------
export function getCourse(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/get-by-id/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getCourseSuccess(response.data));
      // console.log('çàçàç', response.data);
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return 401;
    }
  };
}
// ----------------------------------------------------------------------
export function acceptDelay(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/accept-trip-delay/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.delayCourseSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return 401;
    }
  };
}
// ----------------------------------------------------------------------

export function createCourse(course) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/backoffice-add`,
        course,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createCourseSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createClientCourse(course) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/backoffice-client-add`,
        course,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createCourseSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------
export function updateCourse(id, course) {
  return async (dispatch) => {
    console.log('received', course);
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/backoffice-update/${id}`,
        course,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateCourseSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function deleteCourse(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteCourseSuccess(response.data));
      console.log(response.status);
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function assignDriver(id, driverId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/assign-trip/${id}/${driverId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.assignDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function assignOneClick(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/one-click-trip/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.assignDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function externalTrip(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/external-trip/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.assignDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function dispatchNow(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/dispatch/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.assignDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function assignExterneTrip(id, externeData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_TRIP}/api/v1/trip/assign-extern-trip/${id}`,
        externeData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.assignDriverSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
