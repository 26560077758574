/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  companyDocuments: [],
  companyDocument: null,
};

const slice = createSlice({
  name: 'companyDocument',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCompanyDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.companyDocuments = action.payload;
    },
    getCompanyDocumentSuccess(state, action) {
      state.isLoading = false;
      state.companyDocument = action.payload;
    },
    createCompanyDocumentSuccess(state, action) {
      state.isLoading = false;
      const newCompanyDocument = action.payload;
      state.companyDocuments = [...state.companyDocuments, newCompanyDocument];
    },
    updateCompanyDocumentSuccess(state, action) {
      state.isLoading = false;
      state.companyDocuments = state.companyDocuments.map((docType) => {
        if (docType.id === action.payload.id) {
          return action.payload;
        }
        return docType;
      });
    },
    deleteDocumentTypeSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const {
  getCompanyDocumentsSuccess,
  getCompanyDocumentSuccess,
  createDocumentTypeSuccess,
  updateCompanyDocumentSuccess,
  deleteDocumentTypeSuccess,
} = slice.actions;

export function getCompanyDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company-document`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      console.log(response.data);
      dispatch(slice.actions.getCompanyDocumentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDocumentType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company-document/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getDocumentTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createCompanyDocument(companyDocument) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company-document`,
        companyDocument,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createCompanyDocumentSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCompanyDocument(id, documentType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/company-document/${id}`,
        documentType,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );

      dispatch(slice.actions.updateCompanyDocumentSuccess(response.data));

      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDocumentType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/document-type/${id}`
      );
      dispatch(slice.actions.deleteDocumentTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
