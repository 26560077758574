import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  selectedTab: 0,
  // selectedTabContent: null,
  tabs: [],
};

const slice = createSlice({
  name: 'dispatchClientTabs',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Tab
    getTabSuccess(state, action) {
      state.isLoading = false;
      state.tabs = action.payload;
    },
    // GET All Tab
    getTabsSuccess(state) {
      state.isLoading = false;
      state.tabs = state.tabs || state.tabs[0];
      state.selectedTab = state.selectedTab || 0;
      const findSelectedTab = state.tabs.find((tab) => tab.id === state.selectedTab);
      // state.selectedTabContent = findSelectedTab?.content || state.tabs[0].content;
    },

    // CREATE Tab
    createTabsSuccess(state, action) {
      const newTab = action.payload;
      state.isLoading = false;
      const exists = {};
      state.tabs.forEach((tab) => {
        exists[tab.label] = true;
      });
      const OwnTabCreated = !exists[newTab.label] || newTab.label === 'Création';
      console.log('Existing tabs:', state.tabs);
      console.log('Tab to be added:', newTab);
      if (OwnTabCreated) {
        state.tabs = [...state.tabs, newTab];
        state.selectedTab = state.tabs.length - 1;
        // state.selectedTabContent = newTab.content;
      } else {
        const tabId = newTab.id;
        state.selectedTab = tabId;
      }
    },

    // UPDATE TAB
    updateTabSuccess(state, action) {
      state.isLoading = false;
      const tabId = action.payload;
      state.selectedTab = tabId;
      // state.selectedTabContent = state.tabs[tabId].content;
    },

    // DELETE Tab
    deleteTabSuccess(state, action) {
      const TabId = action.payload;
      let newSelectedTab = state.selectedTab;
      const updatedTabs = state.tabs.filter((_, i) => i !== TabId);
      if (TabId === state.selectedTab) {
        newSelectedTab = Math.max(0, state.selectedTab - 1);
      } else if (TabId < state.selectedTab) {
        newSelectedTab = state.selectedTab - 1;
      }
      state.tabs = updatedTabs.map((tab, i) => ({
        ...tab,
        TabId: i,
      }));
      state.selectedTab = newSelectedTab;
      // state.selectedTabContent = state.tabs[newSelectedTab].content;
    },
    // DELETE All Tabs
    deleteAllTabsSuccess(state) {
      const updatedTabs = state.tabs.toSpliced(1, state.tabs.length);
      state.selectedTab = 0;
      state.tabs = updatedTabs;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  getTabSuccess,
  getTabsSuccess,
  createTabsSuccess,
  updateTabSuccess,
  deleteTabSuccess,
  deleteAllTabsSuccess,
} = slice.actions;
// ----------------------------------------------------------------------

export function getAllTabs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      dispatch(slice.actions.getTabsSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTab(newTab) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.createTabsSuccess(newTab));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTab(TabId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateTabSuccess(TabId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTab(TabId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteTabSuccess(TabId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAllTabs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteAllTabsSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
