import React, { useEffect } from 'react';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';
import { useDispatch, useSelector } from '../../../redux/store';
import { getUserAuthority } from '../../../redux/slices/users';
import { useAuthContext } from '../../../auth/useAuthContext';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const useUserAuthority = (method, controller) => {
  const { authoritiesuser } = useAuthContext();

  // useEffect(() => {
  //   // back here
  //   dispatch(getUserAuthority(user?.id));
  // }, [dispatch, user]);
  const hasAuthority =
    authoritiesuser?.adminAuthorities.some(
      (authority) =>
        authority.method.method === method && authority.controller.controller === controller
    ) || false;

  return hasAuthority;
};
const ICONS = {
  blog: icon('ic_blog'),
  taxis: icon('ic_taxis'),
  entites: icon('ic_entites'),
  resa: icon('ic_resa'),
  compta: icon('ic_compta'),
  etats: icon('ic_etats'),
  outils: icon('ic_outils'),
  statistiques: icon('ic_statistiques'),
  administration: icon('ic_administration'),
  ads: icon('ic_blog'),
  car: icon('ic_blog'),
  driver: icon('ic_blog'),
  driverStatus: icon('ic_cart'),
  dashboard: icon('ic_dashboard'),
  clients: icon('ic_clients'),
  staff: icon('ic_staff'),
  alerts: icon('ic_alerts'),
  affairs: icon('ic_affairs'),
  codePromo: icon('ic_clientCodePromo'),
  parrainage: icon('ic_parrainage'),
  dispatch: icon('ic_dispatch'),
  // ----
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
};

const NavConfig = () => {
  const checkUserAuthority = useUserAuthority();

  return [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // Taxis
        {
          title: 'taxis',
          path: PATH_DASHBOARD.taxis.root,
          icon: ICONS.taxis,
          children: [
            {
              title: 'Sociétés',
              path: useUserAuthority('VIEW', 'COMPANY') ? PATH_DASHBOARD.taxis.societies : '#',
              disabled: !useUserAuthority('VIEW', 'COMPANY'),
              hide: !useUserAuthority('VIEW', 'COMPANY'),
            },
            {
              title: 'Licences ADS',
              path: useUserAuthority('VIEW', 'LICENSE') ? PATH_DASHBOARD.taxis.licences : '#',
              hide: !useUserAuthority('VIEW', 'LICENSE'),
            },
            {
              title: 'Véhicules',
              path: useUserAuthority('VIEW', 'CAR') ? PATH_DASHBOARD.taxis.cars : '#',
              hide: !useUserAuthority('VIEW', 'CAR'),
            },
            {
              title: 'Chauffeurs',
              path: useUserAuthority('VIEW', 'DRIVER') ? PATH_DASHBOARD.taxis.drivers : '#',
              hide: !useUserAuthority('VIEW', 'DRIVER'),
            },
            {
              title: 'Etat chauffeurs',
              path: useUserAuthority('VIEW', 'DRIVER_AVAILABILITY')
                ? PATH_DASHBOARD.taxis.lifeStatus
                : '#',
              hide: !useUserAuthority('VIEW', 'DRIVER_AVAILABILITY'),
            },
            {
              title: 'contrôle des véhicules',
              path: useUserAuthority('VIEW', 'CHECK') ? PATH_DASHBOARD.taxis.carCheck : '#',
              hide: !useUserAuthority('VIEW', 'CHECK'),
            },
          ],
        },
        {
          title: 'entités',
          path: PATH_DASHBOARD.entites.root,
          icon: ICONS.entites,
          children: [
            {
              title: 'Clients',
              path: useUserAuthority('VIEW', 'CLIENT') ? PATH_DASHBOARD.entites.clients : '#',
              // disabled: !useUserAuthority('VIEW', 'CLIENT'),
              hide: !useUserAuthority('VIEW', 'CLIENT'),
            },
            {
              title: "Donneurs d'ordres",
              path: useUserAuthority('VIEW', 'ORDERING_PARTY')
                ? PATH_DASHBOARD.entites.orders
                : '#',
              hide: !useUserAuthority('VIEW', 'ORDERING_PARTY'),
            },
            {
              title: 'Secteurs',
              path: useUserAuthority('VIEW', 'SECTOR') ? PATH_DASHBOARD.entites.secteurs : '#',
              hide: !useUserAuthority('VIEW', 'SECTOR'),
            },
            {
              title: 'Stations',
              path: useUserAuthority('VIEW', 'CABSTAND') ? PATH_DASHBOARD.entites.stations : '#',
              hide: !useUserAuthority('VIEW', 'CABSTAND'),
            },
            {
              title: 'Adresse favorites',
              path: useUserAuthority('VIEW', 'FAVORITE_ADDRESS')
                ? PATH_DASHBOARD.entites.favoriteAddresses
                : '#',
              hide: !useUserAuthority('VIEW', 'FAVORITE_ADDRESS'),
            },

            {
              title: 'Stations et aéroport',
              path: useUserAuthority('VIEW', 'AIRPORT_STATION')
                ? PATH_DASHBOARD.entites.stationsAirport
                : '#',

              hide: !useUserAuthority('VIEW', 'AIRPORT_STATION'),
            },
            {
              title: 'Rayons',
              path: useUserAuthority('VIEW', 'RIDE_RADIUS') ? PATH_DASHBOARD.entites.rayons : '#',
              hide: !useUserAuthority('VIEW', 'RIDE_RADIUS'),
            },
            {
              title: 'Natures',
              path: useUserAuthority('VIEW', 'TRIP_NATURE') ? PATH_DASHBOARD.entites.natures : '#',
              hide: !useUserAuthority('VIEW', 'TRIP_NATURE'),
            },
          ],
        },
        {
          title: 'résa & courses',
          path: PATH_DASHBOARD.resa.root,
          icon: ICONS.resa,
          children: [
            {
              title: 'Dispatch',
              path: useUserAuthority('VIEW', 'TRIP') ? PATH_DASHBOARD.resa.dispatch : '#',
              hide: !useUserAuthority('VIEW', 'TRIP'),
            },
            // { title: 'Courses réservés', path: PATH_DASHBOARD.resa.reservedCourse },
            {
              title: 'Courses terminées',
              path: useUserAuthority('VIEW', 'FINISHED_TRIP')
                ? PATH_DASHBOARD.resa.coursesTermine
                : '#',
              hide: !useUserAuthority('VIEW', 'FINISHED_TRIP'),
            },
            // { title: 'Prochaines courses', path: PATH_DASHBOARD.resa.nextCourses },
            // {
            //   title: 'Courses clients',
            //   path: useUserAuthority('VIEW', 'TRIP_CLIENT')
            //     ? PATH_DASHBOARD.resa.coursesclients
            //     : '#',
            //   hide: !useUserAuthority('VIEW', 'TRIP_CLIENT'),
            // },

            // { title: 'Plannings', path: PATH_DASHBOARD.resa.planning },
            // { title: 'Régulator', path: PATH_DASHBOARD.resa.regulator },
            // { title: 'Recherche courses', path: PATH_DASHBOARD.resa.searchCourses },
            // { title: 'Approche chauffeurs', path: PATH_DASHBOARD.resa.approche },
          ],
        },
        // {
        //   title: 'compta & factures',
        //   path: PATH_DASHBOARD.compta.root,
        //   icon: ICONS.compta,
        //   children: [],
        // },
        // {
        //   title: 'états',
        //   path: PATH_DASHBOARD.etats.root,
        //   icon: ICONS.etats,
        //   children: [],
        // },
        {
          title: 'outils',
          path: PATH_DASHBOARD.outils.root,
          icon: ICONS.outils,
          children: [
            {
              title: 'Actualites',
              path: useUserAuthority('VIEW', 'NEWS') ? PATH_DASHBOARD.outils.news : '#',
              hide: !useUserAuthority('VIEW', 'NEWS'),
            },
          ],
        },
        {
          title: 'statistiques',
          path: PATH_DASHBOARD.statistiques.root,
          icon: ICONS.statistiques,
          children: [],
        },
        {
          title: 'administration',
          path: PATH_DASHBOARD.administration.root,
          icon: ICONS.administration,
          children: [
            {
              title: 'Utilisateurs Backoffice',
              path: useUserAuthority('VIEW', 'ADMIN') ? PATH_DASHBOARD.administration.users : '#',
              hide: !useUserAuthority('VIEW', 'ADMIN'),
            },
            {
              title: 'Rôles',
              path: useUserAuthority('VIEW', 'ADMIN_AUTHORITY')
                ? PATH_DASHBOARD.administration.roles
                : '#',
              hide: !useUserAuthority('VIEW', 'ADMIN_AUTHORITY'),
            },
            {
              title: 'Options Véhicules',
              path: useUserAuthority('VIEW', 'RIDE_OPTION_CAR')
                ? PATH_DASHBOARD.administration.carOptions
                : '#',
              hide: !useUserAuthority('VIEW', 'RIDE_OPTION_CAR'),
            },
            {
              title: 'Options chauffeurs',
              path: useUserAuthority('VIEW', 'RIDE_OPTION_DRIVER')
                ? PATH_DASHBOARD.administration.driverOptions
                : '#',
              hide: !useUserAuthority('VIEW', 'RIDE_OPTION_DRIVER'),
            },
            {
              title: 'Les services',
              path: useUserAuthority('VIEW', 'RIDE_SERVICE')
                ? PATH_DASHBOARD.administration.services
                : '#',
              hide: !useUserAuthority('VIEW', 'RIDE_SERVICE'),
            },
            {
              title: 'Type des documents',
              path: useUserAuthority('VIEW', 'DOCUMENT_TYPE')
                ? PATH_DASHBOARD.administration.documentType
                : '#',
              hide: !useUserAuthority('VIEW', 'DOCUMENT_TYPE'),
              // roles: ['admin'],
            },
            // { title: 'Commandes', path: PATH_DASHBOARD.administration.commandsManager },
          ],
        },
      ],
    },

    {
      subheader: 'autres',
      items: [
        {
          title: 'Clients',
          path: useUserAuthority('VIEW', 'CLIENT') ? PATH_DASHBOARD.entites.clients : '#',
          hide: !useUserAuthority('VIEW', 'CLIENT'),
          icon: ICONS.clients,
        },
        {
          title: 'Dispatch',
          path: useUserAuthority('VIEW', 'TRIP') ? PATH_DASHBOARD.resa.root : '#',
          hide: !useUserAuthority('VIEW', 'TRIP'),
          icon: ICONS.dispatch,
        },
        // {
        //   title: 'staff vgo',
        //   path: PATH_DASHBOARD.staffVgo.root,
        //   icon: ICONS.staff,
        // },
        // {
        //   title: 'alertes',
        //   path: PATH_DASHBOARD.alerts.root,
        //   icon: ICONS.alerts,
        // },
        // {
        //   title: 'VGO affaires',
        //   path: PATH_DASHBOARD.affaires.root,
        //   icon: ICONS.affairs,
        // },
        // {
        //   title: 'code promo clients',
        //   path: PATH_DASHBOARD.codePromo.root,
        //   icon: ICONS.codePromo,
        // },
        // {
        //   title: 'VGO parrainage',
        //   path: PATH_DASHBOARD.parrainage.root,
        //   icon: ICONS.parrainage,
        // },
      ],
    },
  ];
};

export default NavConfig;
