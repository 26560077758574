import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  helperText: PropTypes.node,
  validationFunc: PropTypes.func,
};

export default function RHFAutocomplete({
  name,
  label,
  defaultValue,
  validationFunc,
  helperText,
  ...other
}) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: validationFunc }}
      render={({ field, selected, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            const selectedValue = newValue !== null ? newValue : '';
            setValue(name, selectedValue, { shouldValidate: true });
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...params}
            />
          )}
          renderOption={(props, option, index) => {
            const key = `listItem-${index}-${option.id}`;
            return (
              <li {...props} key={key}>
                {option?.label}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          {...other}
        />
      )}
    />
  );
}
