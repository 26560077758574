/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axiosEntites';

const initialState = {
  isLoading: false,
  error: null,
  naturalElements: [],
  naturalElement: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'nature',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNaturalElementsSuccess(state, action) {
      state.isLoading = false;
      state.naturalElements = action.payload;
    },
    getNaturalElementSuccess(state, action) {
      state.isLoading = false;
      state.naturalElement = action.payload;
    },
    createNaturalElementSuccess(state, action) {
      state.isLoading = false;
      const newNaturalElement = action.payload;
      state.naturalElements = [...state.naturalElements, newNaturalElement];
    },
    updateNaturalElementSuccess(state, action) {
      state.isLoading = false;
      state.naturalElements = state.naturalElements.map((element) => {
        if (element.id === action.payload.id) {
          return action.payload;
        }
        return element;
      });
    },
    deleteNaturalElementSuccess(state, action) {
      state.isLoading = false;
      const natureId = action.payload;
      state.naturalElements = state.naturalElements.filter((event) => event.id !== natureId);
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.naturalElements = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

export default slice.reducer;

export const {
  getNaturalElementsSuccess,
  getNaturalElementSuccess,
  createNaturalElementSuccess,
  updateNaturalElementSuccess,
  deleteNaturalElementSuccess,
} = slice.actions;

export function getNaturalElements() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/trip-nature`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getNaturalElementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getNaturalElementsFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/trip-nature/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getNaturalElement(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/trip-nature/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getNaturalElementSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createNaturalElement(naturalElement) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/trip-nature`,
        naturalElement,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createNaturalElementSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateNaturalElement(id, naturalElement) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/trip-nature/${id}`,
        naturalElement,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateNaturalElementSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteNaturalElement(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/trip-nature/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteNaturalElementSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
