import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { MenuItem, Stack, Badge } from '@mui/material';

import Iconify from '../../../components/iconify';
// components
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { PATH_DASHBOARD } from '../../../routes/paths';
import ChatNavDrawer from '../../../sections/@dashboard/chat/nav/ChatNavDrawer';
import { ChatMessageList } from '../../../sections/@dashboard/chat';

// ----------------------------------------------------------------------

export default function ChatPopover() {
  const navigate = useNavigate();
  const [selectedContact, setSelectedContact] = useState(null);

  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleChatPopover = () => {
    // navigate(PATH_DASHBOARD.chat.root);
    setOpenUsersDialog(true);
  };

  // const { pathname } = useLocation();
  // console.log('pathname', pathname);
  return (
    <>
      <IconButtonAnimate color="default" onClick={handleChatPopover} sx={{ width: 40, height: 40 }}>
        {/* <Badge badgeContent={1} color="error" showZero={false}> */}
        <Iconify icon="streamline:mail-chat-bubble-typing-oval-messages-message-bubble-typing-chat" />
        {/* </Badge> */}
      </IconButtonAnimate>
      {openUsersDialog && (
        <ChatNavDrawer
          setSelectedContact={setSelectedContact}
          selectedContact={selectedContact}
          setOpenDrawer={setOpenDrawer}
          open={openUsersDialog}
          onClose={() => setOpenUsersDialog(false)}
        />
      )}
      {openDrawer && (
        <ChatMessageList
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          conversation={selectedContact}
        />
      )}
    </>
  );
}
