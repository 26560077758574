/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosEntites';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  favoriteAddresses: [],
  favoriteAddress: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'favorite-address',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET favoriteAddresss
    getFavoriteAddressesSuccess(state, action) {
      state.isLoading = false;
      state.favoriteAddresses = action.payload;
    },

    // GET favoriteAddress
    getFavoriteAddressSuccess(state, action) {
      state.isLoading = false;
      state.favoriteAddress = action.payload;
    },
    //  Create favoriteAddress
    createFavoriteAddressSuccess(state, action) {
      state.isLoading = false;
      const newFavoriteAddress = action.payload;
      state.favoriteAddresses = [...state.favoriteAddresses, newFavoriteAddress];
    },
    // Update favoriteAddress
    updateFavoriteAddressSuccess(state, action) {
      state.isLoading = false;
      state.favoriteAddresses = state.favoriteAddresses.map((favoriteAddress) => {
        if (favoriteAddress.id === action.payload.id) {
          return action.payload;
        }
        return favoriteAddress;
      });
    },
    // Delete favoriteAddress
    deleteFavoriteAddressSuccess(state, action) {
      state.isLoading = false;
      const favoriteAddressId = action.payload;
      state.favoriteAddresses = state.favoriteAddresses.filter(
        (event) => event.id !== favoriteAddressId
      );
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.favoriteAddresses = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getFavoriteAddressesSuccess,
  getFavoriteAddressSuccess,
  createFavoriteAddressSuccess,
  updateFavoriteAddressSuccess,
  deleteFavoriteAddressSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getFavoriteAddresses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/favorite-address`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFavoriteAddressesSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getFavoriteAddressesFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/favorite-address/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getFavoriteAddress(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/favorite-address/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFavoriteAddressSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createFavoriteAddress(favoriteAddress) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/favorite-address`,
        favoriteAddress,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createFavoriteAddressSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateFavoriteAddress(id, favoriteAddress) {
  console.log('id:', id, 'favoriteAddress:', favoriteAddress);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/favorite-address/${id}`,
        favoriteAddress,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateFavoriteAddressSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteFavoriteAddress(favoriteAddressId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/favorite-address/${favoriteAddressId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteFavoriteAddressSuccess({ favoriteAddressId }));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
