import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  documentTypes: [],
  documentType: null,
};

const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getDocumentTypesSuccess(state, action) {
      state.isLoading = false;
      state.documentTypes = action.payload;
    },
    getDocumentTypeSuccess(state, action) {
      state.isLoading = false;
      state.documentType = action.payload;
    },
    createDocumentTypeSuccess(state, action) {
      state.isLoading = false;
      const newDocumentType = action.payload;
      state.documentTypes = [...state.documentTypes, newDocumentType];
    },
    updateDocumentTypeSuccess(state, action) {
      state.isLoading = false;
      state.documentTypes = state.documentTypes?.map((docType) => {
        if (docType.id === action.payload.id) {
          return action.payload;
        }
        return docType;
      });
    },
    deleteDocumentTypeSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const {
  getDocumentTypesSuccess,
  getDocumentTypeSuccess,
  createDocumentTypeSuccess,
  updateDocumentTypeSuccess,
  deleteDocumentTypeSuccess,
} = slice.actions;

export function getDocumentTypesByNature(nature) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/document-type/get-by-nature/${nature}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getDocumentTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDocumentTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/document-type`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getDocumentTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDocumentType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/document-type/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getDocumentTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createDocumentType(documentType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/document-type`,
        documentType,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createDocumentTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDocumentType(id, documentType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/document-type/${id}`,
        documentType,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateDocumentTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDocumentType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/document-type/${id}`
      );
      dispatch(slice.actions.deleteDocumentTypeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
