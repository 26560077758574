import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  cars: [],
  companyADSList: [],
  car: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'car',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET cars
    getCarsSuccess(state, action) {
      state.isLoading = false;
      state.cars = action.payload;
    },

    // GET car
    getCarSuccess(state, action) {
      state.isLoading = false;
      state.car = action.payload;
    },
    //
    // GET Company ADS
    getCompanyADSSuccess(state, action) {
      state.isLoading = false;
      state.companyADSList = action.payload;
    },
    //  Create car
    createCarSuccess(state, action) {
      state.isLoading = false;
      const newCar = action.payload;
      state.cars = [...state.cars, newCar];
    },
    // Update car
    updateCarSuccess(state, action) {
      state.isLoading = false;
      state.cars = state.cars.map((car) => {
        if (car.id === action.payload.id) {
          return action.payload;
        }
        return car;
      });
    },
    // Delete car
    deleteCarSuccess(state, action) {
      state.isLoading = false;
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.cars = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCarsSuccess,
  getCarSuccess,
  createCarSuccess,
  updateCarSuccess,
  deleteCarSuccess,
  getCompanyADSSuccess,
} = slice.actions;

// ----------------------------------------------------------------------
/*eslint-disable*/
export function getCars() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      dispatch(slice.actions.getCarsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}
//
export function getCarsFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car/filter`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCar(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`car/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      dispatch(slice.actions.getCarSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function createCar(car) {
  console.log('car received:', car);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car`,
        car,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createCarSuccess(response.data));
      return response.status;
    } catch (error) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateCar(id, car) {
  console.log('Data received', car);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('before response:', id, car);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car/${id}`,
        car,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      console.log('response:', response.data);
      dispatch(slice.actions.updateCarSuccess(response.data));
      return response.status;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCompanyADS(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/license/find-by-company/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getCompanyADSSuccess(response.data));
      return response.status;
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
