import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import dispatchTabsReducer from './slices/dispatchTabs';
import reservedTabsReducer from './slices/reservedTabs';
import companyReducer from './slices/company';
import licenseReducer from './slices/license';
import carReducer from './slices/car';
import driverReducer from './slices/driver';
import driverStatusReducer from './slices/driverStatus';
import clientReducer from './slices/client';
import rideOptionReducer from './slices/rideOptions';
import dispatchReducer from './slices/dispatch';
import usersReducer from './slices/users';
import zonesReducer from './slices/zone';
import stationsReducer from './slices/station';
import favoriteAddressReducer from './slices/favoriteAddress';
import servicesReducer from './slices/service';
import airportStationReducer from './slices/airportStation';
import selectedTripsReducer from './slices/selectedTrips';
import reservedSelectedTripsReducer from './slices/reservedSelectedTrips';
import returnSelectedTripsReducer from './slices/returnSelectedTrips';
import finishedSelectedTripsReducer from './slices/finishedSelectedTrips';
import documentTypeReducer from './slices/documentType';
import adminAuthorityReducer from './slices/adminAuthority';
import controllerMethodReducer from './slices/controllerMethods';
import companyDocumentReducer from './slices/companyDocument';
import carDocumentReducer from './slices/carDocument';
import licenseDocumentReducer from './slices/licenseDocument';
import driverDocumentReducer from './slices/driverDocument';
import rayonReducer from './slices/rayon';
import dispatchClientTabsReducer from './slices/dispatchClientTabs';
import orderingPartyReducer from './slices/orderingParty';
import newsReducer from './slices/news';
import carCheckReducer from './slices/carCheck';
import natureReducer from './slices/nature';
import chatReducer from './slices/chat';
import driverOptionReducer from './slices/driverOptions';
import dispatchTabsFinishedReducer from './slices/dispatchTabsFinished';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const dispatchTabsPersistConfig = {
  key: 'dispatchTabs',
  storage,
  keyPrefix: 'redux-',
  // TODO: remove this when we have proper storage
  whitelist: [],
};
export const reservedTabsPersistConfig = {
  key: 'reservedTabs',
  storage,
  keyPrefix: 'redux-',
  // TODO: remove this when we have proper storage
  whitelist: [],
};

const rootReducer = combineReducers({
  license: licenseReducer,
  company: companyReducer,
  car: carReducer,
  driver: driverReducer,
  driverStatus: driverStatusReducer,
  client: clientReducer,
  rideOption: rideOptionReducer,
  dispatch: dispatchReducer,
  useradmin: usersReducer,
  zone: zonesReducer,
  station: stationsReducer,
  favoriteAddress: favoriteAddressReducer,
  airportStations: airportStationReducer,
  service: servicesReducer,
  selectedTrips: selectedTripsReducer,
  reservedSelectedTrips: reservedSelectedTripsReducer,
  returnSelectedTrip: returnSelectedTripsReducer,
  finishedSelectedTrips: finishedSelectedTripsReducer,
  documentTypes: documentTypeReducer,
  adminAuthorities: adminAuthorityReducer,
  controllerMethods: controllerMethodReducer,
  dispatchClientTabs: dispatchClientTabsReducer,
  orderingParty: orderingPartyReducer,
  DispatchTabs: persistReducer(dispatchTabsPersistConfig, dispatchTabsReducer),
  ReservedTabs: persistReducer(reservedTabsPersistConfig, reservedTabsReducer),
  companyDocument: companyDocumentReducer,
  carDocument: carDocumentReducer,
  licenseDocument: licenseDocumentReducer,
  driverDocument: driverDocumentReducer,
  rayon: rayonReducer,
  news: newsReducer,
  carCheck: carCheckReducer,
  nature: natureReducer,
  chat: chatReducer,
  driverOptions: driverOptionReducer,
  dispatchTabsFinished: dispatchTabsFinishedReducer,
});

export default rootReducer;
