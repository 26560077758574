/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosEntites';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  stations: [],
  station: null,
};

const slice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET stations
    getStationsSuccess(state, action) {
      state.isLoading = false;
      state.stations = action.payload;
    },

    // GET station
    getStationSuccess(state, action) {
      state.isLoading = false;
      state.station = action.payload;
    },
    //  Create station
    createStationSuccess(state, action) {
      state.isLoading = false;
      const newStation = action.payload;
      state.stations = [...state.stations, newStation];
    },
    // Update station
    updateStationSuccess(state, action) {
      state.isLoading = false;
      state.stations = state.stations.map((station) => {
        if (station.id === action.payload.id) {
          return action.payload;
        }
        return station;
      });
    },
    // Delete station
    deleteStationSuccess(state, action) {
      state.isLoading = false;
      const stationId = action.payload;
      state.stations = state.stations.filter((event) => event.id !== stationId);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getStationsSuccess,
  getStationSuccess,
  createStationSuccess,
  updateStationSuccess,
  deleteStationSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getStations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_SETUP}/api/v1/cabstand`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      dispatch(slice.actions.getStationsSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/cabstand/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getStationSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createStation(station) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/cabstand`,
        station,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createStationSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateStation(id, station) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/cabstand/${id}`,
        station,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateStationSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteStation(stationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/cabstand/${stationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteStationSuccess({ stationId }));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
