import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  rideOptions: [],
  //   driverOptions: [],
  rideOption: null,
  totalCount: 0,
};

const slice = createSlice({
  name: 'rideOption',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET rideOptions
    getRideOptionsSuccess(state, action) {
      state.isLoading = false;
      state.rideOptions = action.payload;
    },

    // GET rideOption
    getRideOptionSuccess(state, action) {
      state.isLoading = false;
      state.rideOption = action.payload;
    },
    //  Create rideOption
    createRideOptionSuccess(state, action) {
      state.isLoading = false;
      const newRideOption = action.payload;
      state.rideOptions = [...state.rideOptions, newRideOption];
    },
    // Update rideOption
    updateRideOptionSuccess(state, action) {
      state.isLoading = false;
      state.rideOptions = state.rideOptions.map((rideOption) => {
        if (rideOption.id === action.payload.id) {
          return action.payload;
        }
        return rideOption;
      });
    },
    // Delete rideOption
    deleteRideOptionSuccess(state, action) {
      state.isLoading = false;
      const rideOptionId = action.payload;
      state.rideOptions = state.rideOptions.filter((option) => option.id !== rideOptionId);
    },
    getFiltredDataSuccess(state, action) {
      state.isLoading = false;
      state.rideOptions = action.payload.data;
      state.totalCount = action.payload.meta.total;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getRideOptionsSuccess,
  getRideOptionSuccess,
  createRideOptionSuccess,
  updateRideOptionSuccess,
  deleteRideOptionSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

/*eslint-disable*/
export function getRideOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ride-option`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getRideOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getDriverOptionsFiltredData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ride-option/filter-driver`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getFiltredDataSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getRideOption(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ride-option/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getRideOptionSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createRideOption(rideOption) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ride-option`,
        rideOption,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createRideOptionSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateRideOption(id, rideOption) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ride-option/${id}`,
        rideOption,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateRideOptionSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function deleteRideOption(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/ride-option/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteRideOptionSuccess(id));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
