/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  carDocuments: [],
  carDocument: null,
};

const slice = createSlice({
  name: 'carDocument',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCarDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.carDocuments = action.payload;
    },
    getCarDocumentSuccess(state, action) {
      state.isLoading = false;
      state.carDocument = action.payload;
    },
    createCarDocumentSuccess(state, action) {
      state.isLoading = false;
      const newCarDocument = action.payload;
      state.carDocuments = [...state.carDocuments, newCarDocument];
    },
    updateCarDocumentSuccess(state, action) {
      state.isLoading = false;
      state.carDocuments = state.carDocuments.map((carDoc) => {
        if (carDoc.id === action.payload.id) {
          return action.payload;
        }
        return carDoc;
      });
    },
    deleteCarDocumentSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const {
  getCarDocumentsSuccess,
  getCarDocumentSuccess,
  createCarTypeSuccess,
  updateCarDocumentSuccess,
  deleteCarDocumentSuccess,
} = slice.actions;

export function getCarDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car-document`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      console.log(response.data);
      dispatch(slice.actions.getCarDocumentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCarDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car-document/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getCarDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createCarDocument(carDocument) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car-document`,
        carDocument,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createCarDocumentSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCarDocument(id, carDocument) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car-document/${id}`,
        carDocument,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateCarDocumentSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCarDocument(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/car-document/${id}`
      );
      dispatch(slice.actions.deleteCarDocumentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
