/*eslint-disable*/
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosUsers';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  useradmin: null,
  adminEdit: null,
  userAuthority: null,
};

const slice = createSlice({
  name: 'useradmin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Users
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET User
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.useradmin = action.payload;
    },
    getAdminSuccess(state, action) {
      state.isLoading = false;
      state.adminEdit = action.payload;
    },
    getAdminAuthoritySuccess(state, action) {
      state.isLoading = false;
      state.userAuthority = action.payload;
    },
    //  Create User
    createUserSuccess(state, action) {
      state.isLoading = false;
      const newUser = action.payload;
      state.users = [...state.users, newUser];
    },
    // Update User
    updateUserSuccess(state, action) {
      state.isLoading = false;
      state.users = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        }
        return user;
      });
    },
    // Delete User
    deleteUserSuccess(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getUsersSuccess,
  getUserSuccess,
  getAdminSuccess,
  getAdminAuthoritySuccess,
  createUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
      dispatch(slice.actions.getUsersSuccess(response.data));
      console.log('ssss', response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      // console.log('user data ', response.data);
      dispatch(slice.actions.getUserSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getUserAuthority(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin/authority/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getAdminAuthoritySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAdminEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.getAdminSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createUser(User) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin`,
        User,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.createUserSuccess(response.data));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------
export function updateUser(id, User) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin/${id}`,
        User,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.updateUserSuccess(response.data));
      return response.status;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return 400;
    }
  };
}

export function deleteUser(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL_SETUP}/api/v1/admin/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(slice.actions.deleteUserSuccess({ userId }));
      return response.status;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
