// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  // blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    // ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    // analytics: path(ROOTS_DASHBOARD, '/analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking'),
    // file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  // !NOTE: New Menu
  staffVgo: { root: path(ROOTS_DASHBOARD, '/staffVgo') },
  alerts: { root: path(ROOTS_DASHBOARD, '/alerts') },
  affaires: { root: path(ROOTS_DASHBOARD, '/affaires') },
  parrainage: { root: path(ROOTS_DASHBOARD, '/parrainage') },
  codePromo: { root: path(ROOTS_DASHBOARD, '/promoCode') },
  taxis: {
    root: path(ROOTS_DASHBOARD, '/taxis'),
    societies: path(ROOTS_DASHBOARD, '/taxis/societies'),
    newSocietie: path(ROOTS_DASHBOARD, '/taxis/societies/new'),
    editSocietie: (id) => path(ROOTS_DASHBOARD, `/taxis/societies/${id}/edit`),
    licences: path(ROOTS_DASHBOARD, '/taxis/licences'),
    newLicences: path(ROOTS_DASHBOARD, '/taxis/licences/new'),
    editLicences: (id) => path(ROOTS_DASHBOARD, `/taxis/licences/${id}/edit`),
    cars: path(ROOTS_DASHBOARD, '/taxis/cars'),
    newCars: path(ROOTS_DASHBOARD, '/taxis/cars/new'),
    editCars: (id) => path(ROOTS_DASHBOARD, `/taxis/cars/${id}/edit`),
    drivers: path(ROOTS_DASHBOARD, '/taxis/drivers'),
    newDrivers: path(ROOTS_DASHBOARD, '/taxis/drivers/new'),
    editDrivers: (id) => path(ROOTS_DASHBOARD, `/taxis/drivers/${id}/edit`),
    lifeStatus: path(ROOTS_DASHBOARD, '/taxis/driver-status'),
    history: (id) => path(ROOTS_DASHBOARD, `/taxis/driver-status/${id}/history`),
    carCheck: path(ROOTS_DASHBOARD, '/taxis/cars-check'),

    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  entites: {
    root: path(ROOTS_DASHBOARD, '/entites'),
    clients: path(ROOTS_DASHBOARD, '/entites/clients'),
    newClient: path(ROOTS_DASHBOARD, '/entites/clients/new'),
    editClient: (id) => path(ROOTS_DASHBOARD, `/entites/clients/${id}/edit`),
    orders: path(ROOTS_DASHBOARD, '/entites/orders'),
    newOrder: path(ROOTS_DASHBOARD, '/entites/orders/new'),
    editOrder: (id) => path(ROOTS_DASHBOARD, `/entites/orders/${id}/edit`),
    secteurs: path(ROOTS_DASHBOARD, '/entites/secteurs'),
    stations: path(ROOTS_DASHBOARD, '/entites/stations'),
    favoriteAddresses: path(ROOTS_DASHBOARD, '/entites/favoriteAddresses'),
    newFavoriteAddress: path(ROOTS_DASHBOARD, '/entites/favoriteAddresses/new'),
    editFavoriteAddress: (id) => path(ROOTS_DASHBOARD, `/entites/favoriteAddresses/${id}/edit`),
    stationsAirport: path(ROOTS_DASHBOARD, '/entites/stationsAirport'),
    newAirportStation: path(ROOTS_DASHBOARD, '/entites/stationsAirport/new'),
    editAirportStation: (id) => path(ROOTS_DASHBOARD, `/entites/stationsAirport/${id}/edit`),
    rayons: path(ROOTS_DASHBOARD, '/entites/rayons'),
    newRayon: path(ROOTS_DASHBOARD, '/entites/rayons/new'),
    editRayon: (id) => path(ROOTS_DASHBOARD, `/entites/rayons/${id}/edit`),
    natures: path(ROOTS_DASHBOARD, '/entites/natures'),
    newNature: path(ROOTS_DASHBOARD, '/entites/natures/new'),
    editNature: (id) => path(ROOTS_DASHBOARD, `/entites/natures/${id}/edit`),
  },
  resa: {
    root: path(ROOTS_DASHBOARD, '/resaCourses'),
    dispatch: path(ROOTS_DASHBOARD, '/resaCourses/dispatch'),
    newCourses: path(ROOTS_DASHBOARD, '/resaCourses/dispatch/newCourses'),
    coursesTermine: path(ROOTS_DASHBOARD, '/resaCourses/coursesTermine'),
    // editClient: (id) => path(ROOTS_DASHBOARD, `/entites/client/${id}/edit`),
    coursesclients: path(ROOTS_DASHBOARD, '/resaCourses/courseclients'),
    reservedCourse: path(ROOTS_DASHBOARD, '/resaCourses/ReservedCourse'),
    planning: path(ROOTS_DASHBOARD, '/resaCourses/planning'),
    regulator: path(ROOTS_DASHBOARD, '/resaCourses/regulator'),
    searchCourses: path(ROOTS_DASHBOARD, '/resaCourses/searchCourses'),
    approche: path(ROOTS_DASHBOARD, '/resaCourses/approche'),
  },
  compta: {
    root: path(ROOTS_DASHBOARD, '/compta'),
  },
  etats: {
    root: path(ROOTS_DASHBOARD, '/etats'),
  },
  outils: {
    root: path(ROOTS_DASHBOARD, '/outils'),
    news: path(ROOTS_DASHBOARD, '/outils/news'),
    newNews: path(ROOTS_DASHBOARD, '/outils/news/new'),
    editNews: (id) => path(ROOTS_DASHBOARD, `/outils/news/${id}/edit`),
  },
  statistiques: {
    root: path(ROOTS_DASHBOARD, '/statistiques'),
  },

  administration: {
    root: path(ROOTS_DASHBOARD, '/administration'),
    users: path(ROOTS_DASHBOARD, '/administration/users'),
    roles: path(ROOTS_DASHBOARD, '/administration/roles'),
    newUser: path(ROOTS_DASHBOARD, '/administration/users/new'),
    editUser: (id) => path(ROOTS_DASHBOARD, `/administration/users/${id}/edit`),
    commandsManager: path(ROOTS_DASHBOARD, '/coming-soon'),
    carOptions: path(ROOTS_DASHBOARD, '/administration/carOptions'),
    driverOptions: path(ROOTS_DASHBOARD, '/administration/driverOptions'),
    services: path(ROOTS_DASHBOARD, '/administration/services'),
    documentType: path(ROOTS_DASHBOARD, '/administration/documentType'),
    newService: path(ROOTS_DASHBOARD, '/administration/service/new'),
    editService: (id) => path(ROOTS_DASHBOARD, `/administration/service/${id}/edit`),
  },
  clients: {
    root: path(ROOTS_DASHBOARD, '/clients'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};
