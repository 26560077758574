import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  // contacts: { byId: {}, allIds: [] },
  // conversations: { byId: {}, allIds: [] },
  contacts: [],
  conversations: [],
  activeConversationId: null,
  participants: [],
  recipients: [],
  contact: null,
  totalMessages: 0,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      state.isLoading = false;
      // const contacts = action.payload;
      state.contacts = action.payload;
      // state.contacts.byId = keyBy(contacts, 'id');
      // state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = keyBy(conversations, 'id');
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      // const conversation = action.payload;

      // if (conversation) {
      //   state.conversations.byId[conversation.id] = conversation;
      //   state.activeConversationId = conversation.id;
      //   if (!state.conversations.allIds.includes(conversation.id)) {
      //     state.conversations.allIds.push(conversation.id);
      //   }
      // } else {
      //   state.activeConversationId = null;
      // }
      state.isLoading = false;
      state.totalMessages = action.payload.meta.total;
    },

    // ON SEND MESSAGE
    sendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, sentAt, sender } = conversation;

      const newMessage = {
        id: messageId,
        content: message,
        // contentType,
        // attachments,
        sentAt,
        sender,
      };
      // const contact = state.contacts.filter((item) => item.id === conversationId);
      const existingFieldIndex = state.contacts.findIndex((item) => item.id === conversationId);
      state.contacts[existingFieldIndex].messages.push(newMessage);
      // state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
    getCurrentContact(state, action) {
      const contact = state.contacts.filter((item) => item.id === action.payload);
      state.contact = contact;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, sendMessage, resetActiveConversation, getCurrentContact } =
  slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('https://api-dev-minimal-v4.vercel.app/api/chat/contacts');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/conversation/back-office`
      );

      dispatch(slice.actions.getContactsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'https://api-dev-minimal-v4.vercel.app/api/chat/conversations'
      );
      dispatch(slice.actions.getConversationsSuccess(response.data.conversations));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function getMessages(conversationKey, setMessages, page, pageCount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/conversation/messages/${conversationKey}`,
        {
          params: {
            pageNumber: page,
            pageCount,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );

      setMessages((prev) => [...response.data.data, ...prev]);
      dispatch(slice.actions.getConversationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//
export function addMessage(receiver, setMessages, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_COMPANY}/api/v1/conversation/back-office/${receiver}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      setMessages((prev) => [...prev, response.data]);
      dispatch(slice.actions.getConversationSuccess(response.data.conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'https://api-dev-minimal-v4.vercel.app/api/chat/conversation',
        {
          params: { conversationKey },
        }
      );
      dispatch(slice.actions.getConversationSuccess(response.data.conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('https://api-dev-minimal-v4.vercel.app/api/chat/conversation/mark-as-seen', {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'https://api-dev-minimal-v4.vercel.app/api/chat/participants',
        {
          params: { conversationKey },
        }
      );
      dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
