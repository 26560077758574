import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import frLocale from 'date-fns/locale/fr';
// import format from 'date-fns/format';

KeyboardDatePickerExample.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

function KeyboardDatePickerExample({ label, name }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DatePicker
            label={label}
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy"
            // format={(date) => format(date, 'MM-dd-yyyy')}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default KeyboardDatePickerExample;
