import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

KeyboardDatePickerExample.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  tripDate: PropTypes.instanceOf(Date), // New prop for tripDate
  anticipation: PropTypes.number, // New prop for anticipation
};

function KeyboardDatePickerExample({ label, name, tripDate, anticipation }) {
  const [initialValue, setInitialValue] = useState(new Date());
  const { control, watch, setValue } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (tripDate && anticipation !== undefined) {
      const initialDate = new Date(tripDate);
      initialDate.setMinutes(initialDate.getMinutes() - Number(anticipation));
      setInitialValue(initialDate);
      setValue(name, initialDate);
    }
  }, [tripDate, anticipation, setValue, name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          disabled
          label={label}
          defaultValue={initialValue}
          value={initialValue}
          // minutesStep={5}
          //   onChange={(newValue) => {
          //     field.onChange(newValue);
          //   }}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
          inputFormat="dd/MM/yyyy HH:mm"
          renderInput={(params) => (
            <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
          )}
        />
      )}
    />
  );
}

export default KeyboardDatePickerExample;
